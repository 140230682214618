@charset "UTF-8";
/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */
/* Import Sass mixins, variables, Compass modules, etc. */
/* The OBT2 Simple Grid System */
/* line 1, ../sass/_main.scss */
html {
  margin: 0 !important;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
  font-family: "Lato", "sans-serif";
  font-weight: 400;
  color: #434343;
  font-size: 87.5%;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* line 16, ../sass/_main.scss */
body {
  margin: 0 !important;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
  font-family: "Lato", "sans-serif";
  font-weight: 400;
  color: #434343;
  overflow-x: hidden;
}

/* line 27, ../sass/_main.scss */
strong {
  font-weight: 700;
}

/* line 31, ../sass/_main.scss */
h1 {
  font-size: 36px;
}

/* line 34, ../sass/_main.scss */
h1, h2, h3, h4 {
  line-height: 1.2;
  margin: 20px 0 10px;
}

/* line 39, ../sass/_main.scss */
p {
  margin: 0 0 1em;
  font-size: 14px;
  line-height: 1.4;
}

/* line 45, ../sass/_main.scss */
label {
  font-weight: 700;
}

/* line 49, ../sass/_main.scss */
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

/* line 57, ../sass/_main.scss */
ul.list-terms, ul.list-documentos-manual {
  list-style-type: disc;
  padding-left: 40px;
  margin-bottom: 10px;
}
/* line 61, ../sass/_main.scss */
ul.list-terms li, ul.list-documentos-manual li {
  display: list-item;
  line-height: 2;
}

/* line 68, ../sass/_main.scss */
#wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -150px;
  padding: 0 0 150px;
}

/* line 74, ../sass/_main.scss */
#footer {
  height: 150px;
  font-family: "Noto Sans", "sans-serif";
  font-size: 11px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
}

/* line 81, ../sass/_main.scss */
.fondoprincipal {
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  margin-bottom: 40px;
  background-size: contain;
}
@media (max-width: 1200px) {
  /* line 81, ../sass/_main.scss */
  .fondoprincipal {
    background-position-y: 275px;
  }
}

/* line 91, ../sass/_main.scss */
.fondo1 {
  left: 1px;
}

/* line 94, ../sass/_main.scss */
.fondomain {
  left: 1px;
}

/* line 97, ../sass/_main.scss */
#left1 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background-repeat: repeat-x;
  background: #009bae;
  left: 0;
}

/* line 109, ../sass/_main.scss */
#right1 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background-repeat: repeat-x;
  background: #009bae;
  right: 0;
}

/* line 121, ../sass/_main.scss */
#left2 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background: #009bae;
  background-repeat: repeat-x;
  left: 0;
}

/* line 133, ../sass/_main.scss */
#right2 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background: #009bae;
  background-repeat: repeat-x;
  right: 0;
}

/* line 145, ../sass/_main.scss */
#left3 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background: #009bae;
  background-repeat: repeat-x;
  left: 0;
}

/* line 157, ../sass/_main.scss */
#right3 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background: #009bae;
  background-repeat: repeat-x;
  right: 0;
}

/* line 169, ../sass/_main.scss */
#left4 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background: #002c3e;
  background-repeat: repeat-x;
  left: 0;
}

/* line 181, ../sass/_main.scss */
#right4 {
  position: fixed;
  width: 50%;
  bottom: 0;
  min-height: 100%;
  background-attachment: scroll !important;
  top: 0;
  margin-top: 0;
  background: #002c3e;
  background-repeat: repeat-x;
  right: 0;
}

/* line 193, ../sass/_main.scss */
.logo {
  padding: 18px 15px 15px;
  height: 85px;
  margin-top: 5px;
}
@media (max-width: 480px) {
  /* line 193, ../sass/_main.scss */
  .logo {
    width: 71px;
    height: auto;
    padding: 0 0 10px;
  }
}

/* line 204, ../sass/_main.scss */
.logoAfiliado {
  max-width: 200px;
  max-height: 100px;
}

/* line 212, ../sass/_main.scss */
.logo-index {
  margin: auto;
  height: 65px;
  margin-right: 38px;
}

/* line 217, ../sass/_main.scss */
.row-logo-index {
  margin-top: 75px;
}

/* line 220, ../sass/_main.scss */
.nav-acceder {
  display: none;
}

/* line 223, ../sass/_main.scss */
.nav-buscar {
  display: none;
}

/* line 227, ../sass/_main.scss */
.navbar-toggle,
.navbar-default.navbar-toggle {
  border: none;
  background: transparent !important;
}
/* line 232, ../sass/_main.scss */
.navbar-toggle:hover,
.navbar-default.navbar-toggle:hover {
  background: transparent !important;
}
/* line 236, ../sass/_main.scss */
.navbar-toggle .icon-bar,
.navbar-default.navbar-toggle .icon-bar {
  width: 22px;
  transition: all 0.2s;
  background-color: #ffffff;
}
/* line 241, ../sass/_main.scss */
.navbar-toggle .top-bar,
.navbar-default.navbar-toggle .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  background-color: #ffffff;
}
/* line 246, ../sass/_main.scss */
.navbar-toggle .middle-bar,
.navbar-default.navbar-toggle .middle-bar {
  opacity: 0;
  background-color: #ffffff;
}
/* line 250, ../sass/_main.scss */
.navbar-toggle .bottom-bar,
.navbar-default.navbar-toggle .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  background-color: #ffffff;
}
/* line 257, ../sass/_main.scss */
.navbar-toggle.collapsed .top-bar,
.navbar-default.navbar-toggle.collapsed .top-bar {
  transform: rotate(0);
  background-color: #ffffff;
}
/* line 261, ../sass/_main.scss */
.navbar-toggle.collapsed .middle-bar,
.navbar-default.navbar-toggle.collapsed .middle-bar {
  opacity: 1;
  background-color: #ffffff;
}
/* line 265, ../sass/_main.scss */
.navbar-toggle.collapsed .bottom-bar,
.navbar-default.navbar-toggle.collapsed .bottom-bar {
  transform: rotate(0);
  background-color: #ffffff;
}

/* line 274, ../sass/_main.scss */
.fondo-inversor {
  background-color: #009bae;
}

/* line 277, ../sass/_main.scss */
.fondo-prestatario {
  background-color: #009bae;
}

/* line 280, ../sass/_main.scss */
.fondo-afiliado {
  background-color: #002c3e;
}

/* line 283, ../sass/_main.scss */
.fondo-blanco {
  background-color: #ffffff;
}

/* line 286, ../sass/_main.scss */
.color-afiliado {
  color: #002c3e;
}

/* line 290, ../sass/_main.scss */
.texto-principal-index {
  margin-top: 35px;
  margin-left: -25px;
  font-family: "Lato", "sans-serif";
  font-weight: 700;
  font-size: 20pt;
  line-height: 20pt;
  color: #ffffff;
  text-shadow: 2px 2px 2px rgba(160, 160, 160, 0.6);
}

/* line 301, ../sass/_main.scss */
.title-hero {
  text-align: center;
  margin-top: 500px;
  font-family: "Lato", "sans-serif";
  font-weight: 700;
  font-size: 20pt;
  line-height: 20pt;
  color: #ffffff;
  text-shadow: 2px 2px 2px rgba(160, 160, 160, 0.6);
}
/* line 311, ../sass/_main.scss */
.title-hero.inversor {
  margin-left: 70px;
}

/* line 315, ../sass/_main.scss */
.title-big {
  font-size: 39px;
  font-family: "Lato", "sans-serif";
  font-weight: 700;
  line-height: 47px;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(160, 160, 160, 0.8);
  margin-top: 0;
  margin-bottom: 0;
}
/* line 325, ../sass/_main.scss */
.title-big.prestatario {
  text-align: left;
  padding: 50px 30px;
}
/* line 328, ../sass/_main.scss */
.title-big.prestatario.title-big-prestatario-landing {
  padding-top: 178px;
}
@media (max-width: 767px) {
  /* line 328, ../sass/_main.scss */
  .title-big.prestatario.title-big-prestatario-landing {
    padding-top: 12px;
    text-align: center;
  }
}
/* line 336, ../sass/_main.scss */
.title-big.inversor {
  text-align: right;
  padding: 50px 30px;
}
/* line 340, ../sass/_main.scss */
.title-big.doble-title-box {
  text-align: center;
  padding: 135px 0;
}
/* line 344, ../sass/_main.scss */
.title-big.single-title-box {
  text-align: center;
  padding: 135px 0;
}
/* line 348, ../sass/_main.scss */
.title-big.triple-title-box {
  text-align: center;
  padding: 90px 0;
}
/* line 352, ../sass/_main.scss */
.title-big.quadra-title-box {
  text-align: center;
  padding: 50px 0;
}

/* line 357, ../sass/_main.scss */
.title-black-xl {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 48px;
  font-weight: 700;
}
/* line 362, ../sass/_main.scss */
.title-black-xl.prestatario {
  color: #009bae;
}
/* line 365, ../sass/_main.scss */
.title-black-xl.afiliado {
  color: #002c3e;
}

/* line 369, ../sass/_main.scss */
.title-black-lg {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 35px;
  font-weight: 700;
}

/* line 375, ../sass/_main.scss */
.title-black {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 24px;
  font-weight: 700;
}

/* line 381, ../sass/_main.scss */
.title-black-md {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 20px;
  font-weight: 700;
}
/* line 386, ../sass/_main.scss */
.title-black-md.nodata {
  margin: 75px 0;
  color: #adadad;
}
@media (max-width: 767px) {
  /* line 386, ../sass/_main.scss */
  .title-black-md.nodata {
    text-align: center;
    margin: 20px;
  }
}
/* line 393, ../sass/_main.scss */
.title-black-md.nodata.icon {
  text-align: right;
}
@media (max-width: 767px) {
  /* line 393, ../sass/_main.scss */
  .title-black-md.nodata.icon {
    text-align: center;
    margin: 20px;
  }
}

/* line 402, ../sass/_main.scss */
.title-black-sm {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 17px;
  font-weight: 700;
}

/* line 408, ../sass/_main.scss */
.std-text {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
}

/* line 415, ../sass/_main.scss */
.std-text-md {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 15px;
  text-align: left;
  font-weight: 300;
}

/* line 422, ../sass/_main.scss */
.std-text-sm {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 14px;
  text-align: left;
  font-weight: 300;
}

/* line 429, ../sass/_main.scss */
.std-text-xs {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 13px;
  text-align: left;
  font-weight: 300;
}

/* line 436, ../sass/_main.scss */
.texto-header-carousel-index {
  color: #ffffff;
  margin: 10px 0 10px 0;
  font-family: "Noto Sans", "sans-serif";
  font-weight: 700;
  font-size: 14px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
}

/* line 444, ../sass/_main.scss */
.texto-carousel-index {
  font-size: 14px;
  line-height: 14px;
}
@media (max-width: 767px) {
  /* line 444, ../sass/_main.scss */
  .texto-carousel-index {
    font-size: 11px;
    line-height: 12px;
  }
}

/* line 452, ../sass/_main.scss */
.texto-condiciones {
  font-size: 11px;
  font-style: italic;
}

/* line 457, ../sass/_main.scss */
.seleccionadosimulador {
  color: #009bae;
  font-weight: 700;
  font-size: 30px;
}

/* line 463, ../sass/_main.scss */
.radioinvisible {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/* line 469, ../sass/_main.scss */
.condiciones-lista {
  list-style: none;
  margin: 0;
  padding: 30px 0 50px;
}
/* line 473, ../sass/_main.scss */
.condiciones-lista li {
  padding-left: 1em;
  text-indent: -.7em;
  padding-top: 10px;
}
/* line 477, ../sass/_main.scss */
.condiciones-lista li:before {
  content: "â€¢ ";
  color: #9ec6ff;
  font-size: 11px;
}

/* line 484, ../sass/_main.scss */
.list-group-item {
  border-radius: 0;
  border: 1px solid #000000;
  font-weight: 300;
  padding: 5px 15px;
}
/* line 490, ../sass/_main.scss */
.list-group-item:first-child {
  border-radius: 0;
}
/* line 494, ../sass/_main.scss */
.list-group-item:last-child {
  border-radius: 0;
}
/* line 498, ../sass/_main.scss */
.list-group-item.tareas-usuario {
  border: 0;
  border-bottom: 1px solid #adadad;
  padding: 30px 15px;
  margin: 0;
  border-radius: 0;
}

/* line 507, ../sass/_main.scss */
a {
  text-decoration: none;
}
/* line 509, ../sass/_main.scss */
a.link-gray-underline {
  color: #a9a9a9;
  text-decoration: underline;
}
/* line 512, ../sass/_main.scss */
a.link-gray-underline:hover {
  color: #5b5b5b;
  text-decoration: underline;
}
/* line 517, ../sass/_main.scss */
a.todo {
  text-decoration: none;
}
/* line 520, ../sass/_main.scss */
a.sin-link {
  display: inline-block;
  color: #5b5b5b;
}
/* line 524, ../sass/_main.scss */
a:hover.sin-link {
  text-decoration: none;
  color: inherit;
}
/* line 528, ../sass/_main.scss */
a:hover.etiqueta {
  text-decoration: none;
}
/* line 531, ../sass/_main.scss */
a.modalFinanciacionId {
  text-decoration: none;
  color: #002c3e;
}

/* line 536, ../sass/_main.scss */
.sin-link {
  color: #434343;
}
/* line 538, ../sass/_main.scss */
.sin-link:hover {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 547, ../sass/_main.scss */
.sin-link:focus {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 556, ../sass/_main.scss */
.sin-link:active {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 564, ../sass/_main.scss */
.sin-link:active.focus {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 573, ../sass/_main.scss */
.sin-link:active:focus {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 583, ../sass/_main.scss */
.sin-link.active {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 591, ../sass/_main.scss */
.sin-link.active.focus {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 600, ../sass/_main.scss */
.sin-link.active:focus {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 610, ../sass/_main.scss */
.sin-link.focus {
  text-decoration: none;
  color: #888;
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 620, ../sass/_main.scss */
.navbar {
  background: rgba(0, 0, 0, 0.05);
  border: none;
}
/* line 623, ../sass/_main.scss */
.navbar .container {
  background: none;
}

/* line 631, ../sass/_main.scss */
.navbar-fixed-bottom {
  border-width: 1px 0;
}

/* line 634, ../sass/_main.scss */
.navbar-footer {
  margin-bottom: 0;
  border-bottom: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding-bottom: 25px;
}

/* line 643, ../sass/_main.scss */
.navbar-subtext {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #ededed;
  font-family: "Noto Sans", "sans-serif";
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
  text-decoration: none;
}
/* line 652, ../sass/_main.scss */
.navbar-subtext a {
  text-decoration: none;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #ededed;
  font-family: "Noto Sans", "sans-serif";
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
  text-decoration: none;
}
/* line 661, ../sass/_main.scss */
.navbar-subtext a:hover, .navbar-subtext a:focus {
  text-decoration: none;
  color: #ffffff;
}

/* line 668, ../sass/_main.scss */
a > .navbar-subtext:hover, a > .navbar-subtext:focus, a > .navbar-subtext:active, a > .navbar-subtext:visited {
  text-decoration: none;
  color: #ededed;
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
}

/* line 675, ../sass/_main.scss */
.nav-new {
  font-family: "Noto Sans", "sans-serif";
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
}
/* line 682, ../sass/_main.scss */
.nav-new#atras a {
  padding: 4px 15px 0;
}
/* line 689, ../sass/_main.scss */
.nav-new#li-ayuda a i {
  cursor: pointer;
}

/* line 695, ../sass/_main.scss */
.separador {
  margin-top: 15px;
  color: #dddddd;
}

/* line 699, ../sass/_main.scss */
.iconos-barrasup {
  line-height: 1.3em;
  vertical-align: 0;
}

/* line 703, ../sass/_main.scss */
.submenu-li {
  border: 1px solid #000000;
  color: #434343;
  margin: 26px 5px 0;
  padding: 0;
}
/* line 708, ../sass/_main.scss */
.submenu-li a {
  color: #434343;
  font-family: "Lato", "sans-serif";
  font-size: 12px;
  font-weight: 700;
  text-shadow: none;
}

/* line 717, ../sass/_main.scss */
.submenu-interior {
  display: block;
  margin-top: 25px;
  margin-bottom: -1px;
  margin-right: -5px;
}

/* line 723, ../sass/_main.scss */
.sub-active {
  background-color: #ededed;
}

/* line 726, ../sass/_main.scss */
.sub-active-inversor {
  background-color: #3fa3b0;
}

/* line 729, ../sass/_main.scss */
.sub-active-afiliado {
  background-color: #9ec9da;
}

/* line 732, ../sass/_main.scss */
.sub-active-prestatario {
  background-color: #3fa3b0;
}

/* line 735, ../sass/_main.scss */
.panel {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 741, ../sass/_main.scss */
.panel.nopanel {
  margin-bottom: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

/* line 750, ../sass/_main.scss */
.panel-default {
  border: #444 1px solid;
}
/* line 752, ../sass/_main.scss */
.panel-default > .panel-heading {
  color: #000000;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #444;
}

/* line 759, ../sass/_main.scss */
.panel-danger {
  border: #ebccd1 1px solid;
}
/* line 761, ../sass/_main.scss */
.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

/* line 767, ../sass/_main.scss */
.panel-footer {
  background-color: #ffffff;
  border-top: 1px solid #000000;
}

/* line 772, ../sass/_main.scss */
.panel-gris {
  background-color: #f6f6f6;
  padding: 20px 15px;
}
/* line 775, ../sass/_main.scss */
.panel-gris.prestatario {
  border-top: 3px solid #009bae;
}
/* line 778, ../sass/_main.scss */
.panel-gris.afiliado {
  border-top: 3px solid #002c3e;
}
/* line 781, ../sass/_main.scss */
.panel-gris.inversor {
  border-top: 3px solid #009bae;
}

/* line 786, ../sass/_main.scss */
.modalzank {
  border-radius: 0;
  padding: 20px;
}
/* line 790, ../sass/_main.scss */
.modalzank .modal-header {
  border-bottom: none;
}
/* line 792, ../sass/_main.scss */
.modalzank .modal-header > .modal-title {
  font-size: 25px;
}
/* line 796, ../sass/_main.scss */
.modalzank .modal-footer {
  border-top: none;
  margin-top: 0;
}

/* line 801, ../sass/_main.scss */
.modal-title {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 26px;
  font-weight: 700;
}

/* line 809, ../sass/_main.scss */
.badge.badge-success {
  background-color: #5cb85c;
}
/* line 812, ../sass/_main.scss */
.badge.badge-warning {
  background-color: #f0ad4e;
}
/* line 815, ../sass/_main.scss */
.badge.badge-danger {
  background-color: #d9534f;
}

/* line 820, ../sass/_main.scss */
.container-carousel {
  background: rgba(0, 0, 0, 0.05);
  margin: 5px;
  padding: 5px;
  height: 150px;
  color: #ffffff;
}

/* line 827, ../sass/_main.scss */
.carousel {
  height: 140px !important;
  background: none !important;
}
/* line 830, ../sass/_main.scss */
.carousel .item {
  height: 140px !important;
  background: none !important;
}
/* line 833, ../sass/_main.scss */
.carousel .item .active {
  height: 140px !important;
  background: none !important;
}
/* line 838, ../sass/_main.scss */
.carousel.carousel-ask {
  height: 580px !important;
}
@media (max-height: 670px) {
  /* line 838, ../sass/_main.scss */
  .carousel.carousel-ask {
    height: 700px !important;
  }
}
@media (max-width: 1200px) {
  /* line 838, ../sass/_main.scss */
  .carousel.carousel-ask {
    height: 700px !important;
  }
}
@media (max-width: 768px) {
  /* line 838, ../sass/_main.scss */
  .carousel.carousel-ask {
    height: 610px !important;
  }
}
@media (max-width: 480px) {
  /* line 838, ../sass/_main.scss */
  .carousel.carousel-ask {
    height: 625px !important;
  }
}
/* line 856, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input {
  display: flex;
  flex-flow: column-reverse;
  /* active state */
}
/* line 861, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input .bar {
  position: relative;
  display: block;
  width: 100%;
}
/* line 866, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input .bar:before, .carousel.carousel-ask .wrapper-input .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: -50px;
  position: absolute;
  background: #009bae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* line 877, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input .bar:before {
  left: 50%;
}
/* line 880, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input .bar:after {
  right: 50%;
}
/* line 885, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input .bar.text-error:before, .carousel.carousel-ask .wrapper-input .bar.text-error:after {
  background: #FF0000;
}
/* line 893, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input input:focus ~ .bar:before, .carousel.carousel-ask .wrapper-input input:focus ~ .bar:after {
  width: 50%;
}
/* line 897, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input label, .carousel.carousel-ask .wrapper-input input {
  transition: all 0.2s;
  touch-action: manipulation;
}
/* line 902, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input input:focus {
  outline: 0;
  background: #fff;
}
/* line 907, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input label {
  line-height: 10px;
  text-align: left;
  font-size: 10px;
  font-family: "Lato", "sans-serif";
  font-weight: 400;
  font-style: italic;
  color: #999999;
}
/* line 917, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(12px, 2.125rem) scale(1.4);
}
/* line 927, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input ::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
/* line 932, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input input:focus::-webkit-input-placeholder {
  opacity: 1;
}
/* line 936, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input input:not(:placeholder-shown) + label,
.carousel.carousel-ask .wrapper-input input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  color: #009bae;
}
/* line 942, ../sass/_main.scss */
.carousel.carousel-ask .wrapper-input input:not(:placeholder-shown) + label.text-error,
.carousel.carousel-ask .wrapper-input input:focus + label.text-error {
  color: #FF0000;
}
/* line 948, ../sass/_main.scss */
.carousel.carousel-ask .carousel-indicators {
  width: 80%;
  margin-left: -40%;
}
/* line 951, ../sass/_main.scss */
.carousel.carousel-ask .carousel-indicators li {
  border: 1px solid #666666;
  cursor: default;
}
/* line 954, ../sass/_main.scss */
.carousel.carousel-ask .carousel-indicators li.active, .carousel.carousel-ask .carousel-indicators li.pasive {
  border: 1px solid #009bae;
  background: #009bae;
}
/* line 960, ../sass/_main.scss */
.carousel.carousel-ask .carousel-indicators.afiliado li {
  border: 1px solid #666666;
  cursor: default;
}
/* line 963, ../sass/_main.scss */
.carousel.carousel-ask .carousel-indicators.afiliado li.active, .carousel.carousel-ask .carousel-indicators.afiliado li.pasive {
  border: 1px solid #002c3e;
  background: #002c3e;
}
/* line 971, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner {
  height: 100%;
  background-color: white;
  box-shadow: 4px 4px 6px #dddddd;
  border: 1px solid #bbbbbb;
}
/* line 976, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item {
  margin: 0;
}
/* line 978, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .header-preguntas {
  display: block;
  max-width: 100%;
  width: 920px;
  height: 200px;
}
/* line 983, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .header-preguntas.prestatario {
  background-color: #009bae;
}
/* line 986, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .header-preguntas.afiliado {
  background-color: #002c3e;
}
/* line 990, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .header-preguntas .title-black-md {
  text-align: center;
  padding: 20px;
  color: white;
  margin: 0;
}
/* line 996, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .header-preguntas .title-big {
  text-align: center;
  padding: 20px;
}
@media (max-width: 767px) {
  /* line 996, ../sass/_main.scss */
  .carousel.carousel-ask .carousel-inner .item .header-preguntas .title-big {
    font-size: 20px;
    line-height: 20px;
  }
}
/* line 1012, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .contenedor-respuestas .respuestas {
  text-align: center;
  line-height: 280px;
  margin: 85px auto 25px;
}
/* line 1019, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .contenedor-respuestas .respuestas .errorASK {
  padding: 0 4px;
  margin: -15px 0 0;
  font-size: 11px;
  font-weight: 400;
  color: #ff0000;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  text-align: left;
  font-family: "Lato", "sans-serif";
}
/* line 1031, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .contenedor-respuestas .respuestas .form-group {
  margin: 0 auto 15px;
}
@media (max-width: 767px) {
  /* line 1012, ../sass/_main.scss */
  .carousel.carousel-ask .carousel-inner .item .contenedor-respuestas .respuestas {
    margin: 20px auto;
  }
}
/* line 1037, ../sass/_main.scss */
.carousel.carousel-ask .carousel-inner .item .contenedor-respuestas .respuestas .contenedor-respuesta {
  display: inline-block;
  width: 95px;
  height: 67px;
  vertical-align: top;
  line-height: normal;
  cursor: pointer;
}
@media (max-width: 767px) {
  /* line 1037, ../sass/_main.scss */
  .carousel.carousel-ask .carousel-inner .item .contenedor-respuestas .respuestas .contenedor-respuesta {
    display: block;
    width: 100%;
    height: 55px;
    margin: 0px 0px;
  }
}

/* line 1060, ../sass/_main.scss */
.pac-container.pac-logo .pac-item {
  white-space: normal !important;
}

/* line 1066, ../sass/_main.scss */
.carousel-transparent-box {
  height: 140px !important;
  background: none !important;
}

/* line 1070, ../sass/_main.scss */
.carousel-inner-transparent-box {
  height: 140px !important;
  background: rgba(0, 0, 0, 0.05);
  color: #ffffff;
}

/* line 1075, ../sass/_main.scss */
.carousel-left-prestamos {
  background-color: rgba(0, 0, 0, 0.09);
  padding: 10px 3px 7px;
  margin-left: -21px;
  margin-top: 48px;
  text-shadow: none;
}

/* line 1083, ../sass/_main.scss */
.carousel-right-prestamos {
  background-color: rgba(0, 0, 0, 0.09);
  padding: 10px 3px 7px;
  margin-right: -22px;
  margin-top: 48px;
  text-shadow: none;
}

/* line 1091, ../sass/_main.scss */
.carousel-left-estadisticas {
  background-color: rgba(0, 0, 0, 0.09);
  padding: 10px 3px 7px;
  margin-left: -66px;
  margin-top: 48px;
  text-shadow: none;
}

/* line 1099, ../sass/_main.scss */
.carousel-right-estadisticas {
  background-color: rgba(0, 0, 0, 0.09);
  padding: 10px 3px 7px;
  margin-right: -66px;
  margin-top: 48px;
  text-shadow: none;
}

/* line 1107, ../sass/_main.scss */
.carousel-left-inversores {
  background-color: rgba(0, 0, 0, 0.09);
  padding: 10px 3px 7px;
  margin-left: -21px;
  margin-top: 48px;
  text-shadow: none;
}

/* line 1115, ../sass/_main.scss */
.carousel-right-inversores {
  background-color: rgba(0, 0, 0, 0.09);
  padding: 10px 3px 7px;
  margin-right: -22px;
  margin-top: 48px;
  text-shadow: none;
}

/* line 1123, ../sass/_main.scss */
.item {
  margin: 0 45px;
}

/* line 1128, ../sass/_main.scss */
.carousel-control.ask {
  width: 40px;
  height: 200px;
}
/* line 1131, ../sass/_main.scss */
.carousel-control.ask.left {
  background: none;
}
/* line 1134, ../sass/_main.scss */
.carousel-control.ask.right {
  display: none;
  background: none;
}
/* line 1139, ../sass/_main.scss */
.carousel-control.index {
  background: none;
  width: 39px;
}
/* line 1142, ../sass/_main.scss */
.carousel-control.index .fa-chevron-left {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -16px;
  width: 28px;
  height: 36px;
  font-size: 30px;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.09);
  margin-left: -20px;
  text-shadow: none;
  line-height: 37px;
}
/* line 1157, ../sass/_main.scss */
.carousel-control.index .fa-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -16px;
  width: 28px;
  height: 36px;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0.09);
  margin-left: -9px;
  text-shadow: none;
  line-height: 37px;
}

/* line 1176, ../sass/_main.scss */
.alert {
  margin-top: 0;
  border-radius: 0;
  padding: 10px;
  font-weight: 300;
}

/* line 1183, ../sass/_main.scss */
.iconoalerta {
  padding: 0 10px;
}

/* line 1186, ../sass/_main.scss */
.btn-file {
  position: relative;
  overflow: hidden;
}
/* line 1189, ../sass/_main.scss */
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #ffffff;
  cursor: inherit;
  display: block;
}

/* line 1206, ../sass/_main.scss */
.btn-base {
  background-color: #ffffff;
  font-family: "Lato", "sans-serif";
  text-transform: uppercase;
  outline: none;
  border-radius: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
@media (max-width: 640px) {
  /* line 1225, ../sass/_main.scss */
  .btn-base .iban-big {
    display: none !important;
  }
  /* line 1228, ../sass/_main.scss */
  .btn-base .iban-oculto {
    display: block !important;
  }
}
@media (max-width: 480px) {
  /* line 1206, ../sass/_main.scss */
  .btn-base {
    max-width: 100%;
    margin-bottom: 0;
  }
}
/* line 1238, ../sass/_main.scss */
.btn-base.btn-neutral, .btn-base button[disabled] {
  border: 2px solid #999;
  color: #999;
}
/* line 1241, ../sass/_main.scss */
.btn-base.btn-neutral:hover, .btn-base.btn-neutral:focus, .btn-base.btn-neutral:active, .btn-base.btn-neutral.active, .btn-base.btn-neutral.activo, .btn-base button[disabled]:hover, .btn-base button[disabled]:focus, .btn-base button[disabled]:active, .btn-base button[disabled].active, .btn-base button[disabled].activo {
  color: white;
  outline: none;
  background-color: #999;
  box-shadow: none;
}
/* line 1249, ../sass/_main.scss */
.btn-base.btn-mainmenu {
  border: none;
  color: #a0a0a0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
/* line 1255, ../sass/_main.scss */
.btn-base.btn-mainmenu:hover, .btn-base.btn-mainmenu:focus, .btn-base.btn-mainmenu:active, .btn-base.btn-mainmenu.active, .btn-base.btn-mainmenu.activo {
  color: white;
  outline: none;
  background-color: #a0a0a0;
  box-shadow: none;
}
/* line 1263, ../sass/_main.scss */
.btn-base.btn-kpis {
  border: none;
  color: #666666;
  background: #e6e6e6;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  text-transform: none;
}
/* line 1271, ../sass/_main.scss */
.btn-base.btn-kpis:hover, .btn-base.btn-kpis:focus, .btn-base.btn-kpis:active, .btn-base.btn-kpis.active, .btn-base.btn-kpis.activo {
  color: white;
  outline: none;
  background-color: #a0a0a0;
  box-shadow: none;
}
/* line 1277, ../sass/_main.scss */
.btn-base.btn-kpis.btn-card-add {
  padding: 62px 55px;
  border-radius: 5px;
  font-size: 20px;
  margin: 10px;
}
/* line 1283, ../sass/_main.scss */
.btn-base.btn-kpis.btn-card {
  padding: 62px 19px;
  border-radius: 5px;
  font-size: 20px;
  color: #aaaaaa;
  margin: 10px;
  background: -moz-linear-gradient(-45deg, #959595 0%, #0d0d0d 46%, #010101 50%, #0a0a0a 53%, #4e4e4e 76%, #383838 87%, #1b1b1b 100%);
  background: -webkit-linear-gradient(-45deg, #959595 0%, #0d0d0d 46%, #010101 50%, #0a0a0a 53%, #4e4e4e 76%, #383838 87%, #1b1b1b 100%);
  background: linear-gradient(135deg, #959595 0%, #0d0d0d 46%, #010101 50%, #0a0a0a 53%, #4e4e4e 76%, #383838 87%, #1b1b1b 100%);
}
/* line 1293, ../sass/_main.scss */
.btn-base.btn-kpis.btn-sdd {
  padding: 62px 26px;
  border-radius: 5px;
  font-size: 20px;
  color: #dddddd;
  margin-bottom: 10px;
  background: #b5bdc8;
  background: -moz-linear-gradient(-45deg, #b5bdc8 0%, #828c95 36%, #28343b 100%);
  background: -webkit-linear-gradient(-45deg, #b5bdc8 0%, #828c95 36%, #28343b 100%);
  background: linear-gradient(135deg, #b5bdc8 0%, #828c95 36%, #28343b 100%);
}
/* line 1306, ../sass/_main.scss */
.btn-base.btn-kpis p {
  white-space: initial;
  margin-bottom: 0;
}
/* line 1311, ../sass/_main.scss */
.btn-base.btn-kpis.btn-inv-dni p {
  float: right;
  max-width: 83%;
}
/* line 1318, ../sass/_main.scss */
.btn-base.btn-prestatario {
  border: 2px solid #009bae;
  color: #009bae;
}
/* line 1321, ../sass/_main.scss */
.btn-base.btn-prestatario:hover, .btn-base.btn-prestatario:focus, .btn-base.btn-prestatario:active, .btn-base.btn-prestatario.active, .btn-base.btn-prestatario.activo {
  color: white;
  outline: none;
  background-color: #009bae;
  box-shadow: none;
}
/* line 1327, ../sass/_main.scss */
.btn-base.btn-prestatario.siguienteItem {
  float: right;
}
@media (max-width: 1200px) {
  /* line 1327, ../sass/_main.scss */
  .btn-base.btn-prestatario.siguienteItem {
    margin: 0 30px;
  }
}
/* line 1336, ../sass/_main.scss */
.btn-base.btn-inversor {
  border: 2px solid #009bae;
  color: #009bae;
}
/* line 1339, ../sass/_main.scss */
.btn-base.btn-inversor:hover, .btn-base.btn-inversor:focus, .btn-base.btn-inversor:active, .btn-base.btn-inversor.active, .btn-base.btn-inversor.activo {
  color: white;
  outline: none;
  background-color: #009bae;
  box-shadow: none;
}
/* line 1347, ../sass/_main.scss */
.btn-base.btn-afiliado {
  border: 2px solid #002c3e;
  color: #002c3e;
}
/* line 1350, ../sass/_main.scss */
.btn-base.btn-afiliado:hover, .btn-base.btn-afiliado:focus, .btn-base.btn-afiliado:active, .btn-base.btn-afiliado.active, .btn-base.btn-afiliado.activo {
  color: white;
  outline: none;
  background-color: #002c3e;
  box-shadow: none;
}

/* line 1359, ../sass/_main.scss */
.btn-superblock {
  width: 100%;
  padding: 40px;
}
@media (max-width: 768px) {
  /* line 1359, ../sass/_main.scss */
  .btn-superblock {
    margin: 0 0 10px 0;
  }
}

/* line 1368, ../sass/_main.scss */
.btn-simulador {
  border: 0;
  border-radius: 0;
  padding: 30px 12px;
  color: #333;
}
/* line 1373, ../sass/_main.scss */
.btn-simulador.afiliado {
  background: #333a3c;
  border-left: 1px solid #0b2e3c;
  border-top: 1px solid #0b2e3c;
}
/* line 1377, ../sass/_main.scss */
.btn-simulador.afiliado.off {
  background: #888;
}
/* line 1380, ../sass/_main.scss */
.btn-simulador.afiliado:first-child {
  border-left: none;
}
/* line 1383, ../sass/_main.scss */
.btn-simulador.afiliado.active, .btn-simulador.afiliado.focus {
  background-color: #9dc4b7;
  outline: none;
}
/* line 1388, ../sass/_main.scss */
.btn-simulador.prestatario {
  background: #eff8ff;
  border-left: 1px solid #b9e1f5;
}
/* line 1391, ../sass/_main.scss */
.btn-simulador.prestatario:first-child {
  border-left: none;
}
/* line 1394, ../sass/_main.scss */
.btn-simulador.prestatario.active, .btn-simulador.prestatario.focus {
  background-color: #b9e1f5;
  outline: none;
}

/* line 1401, ../sass/_main.scss */
.btn-modo {
  border: 0;
  border-radius: 0;
  padding: 12px 12px;
  color: #333;
}
/* line 1406, ../sass/_main.scss */
.btn-modo.afiliado {
  background: #333a3c;
  border-left: 1px solid #0b2e3c;
}
/* line 1409, ../sass/_main.scss */
.btn-modo.afiliado:first-child {
  border-left: none;
}
/* line 1412, ../sass/_main.scss */
.btn-modo.afiliado.active, .btn-modo.afiliado.focus {
  background-color: #9dc4b7;
  outline: none;
}
/* line 1417, ../sass/_main.scss */
.btn-modo.prestatario {
  background: #eff8ff;
  border-left: 1px solid #b9e1f5;
}
/* line 1420, ../sass/_main.scss */
.btn-modo.prestatario:first-child {
  border-left: none;
}
/* line 1423, ../sass/_main.scss */
.btn-modo.prestatario.active, .btn-modo.prestatario.focus {
  background-color: #b9e1f5;
  outline: none;
}

/* line 1430, ../sass/_main.scss */
.btn-tipoInversor {
  width: 100%;
  padding: 40px;
}
@media (max-width: 480px) {
  /* line 1430, ../sass/_main.scss */
  .btn-tipoInversor {
    padding: 20px;
  }
}

/* line 1437, ../sass/_main.scss */
.btn-verificacion-primario {
  margin-bottom: 20px;
  padding: 50px;
  font-size: 18px;
}
/* line 1441, ../sass/_main.scss */
.btn-verificacion-primario:hover {
  margin-bottom: 20px;
  padding: 50px;
  font-size: 18px;
}
/* line 1446, ../sass/_main.scss */
.btn-verificacion-primario:focus {
  margin-bottom: 20px;
  padding: 50px;
  font-size: 18px;
}

/* line 1452, ../sass/_main.scss */
.btn-removeDoc {
  padding-left: 5px;
  color: #434343;
}

/* line 1457, ../sass/_main.scss */
.btn-riesgo-conservador.active, .btn-riesgo-conservador:hover, .btn-riesgo-conservador:focus, .btn-riesgo-conservador:active {
  background: #8ba7ab !important;
}

/* line 1462, ../sass/_main.scss */
.btn-riesgo-moderado.active, .btn-riesgo-moderado:hover, .btn-riesgo-moderado:focus, .btn-riesgo-moderado:active {
  background: #3fa3b0 !important;
}

/* line 1467, ../sass/_main.scss */
.btn-riesgo-agresivo.active, .btn-riesgo-agresivo:hover, .btn-riesgo-agresivo:focus, .btn-riesgo-agresivo:active {
  background: #009bae !important;
}

/* line 1471, ../sass/_main.scss */
.form-margin {
  margin: 25px 0 0;
}

/* line 1474, ../sass/_main.scss */
.input-new {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #898989;
  color: #555;
  outline: 0;
  font-style: italic;
  font-family: "Noto Sans", "sans-serif";
}

/* line 1489, ../sass/_main.scss */
.textarea-new {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #898989;
  color: #555;
  outline: 0;
  font-style: italic;
  font-family: "Noto Sans", "sans-serif";
}

/* line 1503, ../sass/_main.scss */
.select-new {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #898989;
  color: #555;
  outline: 0;
  font-style: italic;
  font-family: "Noto Sans", "sans-serif";
}

/* line 1519, ../sass/_main.scss */
.form-control.input-new:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
}
/* line 1526, ../sass/_main.scss */
.form-control.input-new:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
}
/* line 1534, ../sass/_main.scss */
.form-control.input-new.prestatario:focus {
  border-color: #009bae;
}
/* line 1537, ../sass/_main.scss */
.form-control.input-new.prestatario:active {
  border-color: #009bae;
}
/* line 1540, ../sass/_main.scss */
.form-control.input-new.prestatario.error {
  border-color: red;
}
/* line 1545, ../sass/_main.scss */
.form-control.input-new.inversor:focus {
  border-color: #009bae;
}
/* line 1548, ../sass/_main.scss */
.form-control.input-new.inversor:active {
  border-color: #009bae;
}
/* line 1553, ../sass/_main.scss */
.form-control.input-new.afiliado:focus {
  border-color: #002c3e;
}
/* line 1556, ../sass/_main.scss */
.form-control.input-new.afiliado:active {
  border-color: #002c3e;
}
/* line 1559, ../sass/_main.scss */
.form-control.input-new.afiliado.error {
  border-color: red;
}
/* line 1564, ../sass/_main.scss */
.form-control.input-new[type=file] {
  background: #dddddd;
  border: none;
  box-shadow: none;
}
/* line 1568, ../sass/_main.scss */
.form-control.input-new[type=file]:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #dddddd;
}
/* line 1575, ../sass/_main.scss */
.form-control.input-new[type=file]:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #dddddd;
}
/* line 1583, ../sass/_main.scss */
.form-control.input-code {
  font-size: 32pt;
  padding: 28px 0;
  text-align: center;
}
/* line 1588, ../sass/_main.scss */
.form-control.input-code.inversor:focus, .form-control.input-code.inversor:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
  border-color: #009bae;
}
/* line 1597, ../sass/_main.scss */
.form-control.input-code.prestatario:focus, .form-control.input-code.prestatario:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
  border-color: #009bae;
}
/* line 1606, ../sass/_main.scss */
.form-control.input-code.afiliado:focus, .form-control.input-code.afiliado:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
  border-color: #002c3e;
}
/* line 1614, ../sass/_main.scss */
.form-control.input-code::placeholder {
  color: lightgray;
}
/* line 1619, ../sass/_main.scss */
.form-control.textarea-new:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
}
/* line 1626, ../sass/_main.scss */
.form-control.textarea-new:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
}
/* line 1634, ../sass/_main.scss */
.form-control.textarea-new.prestatario:focus {
  border-color: #93abcf;
}
/* line 1637, ../sass/_main.scss */
.form-control.textarea-new.prestatario:active {
  border-color: #93abcf;
}
/* line 1640, ../sass/_main.scss */
.form-control.textarea-new.prestatario.error {
  border-color: red;
}
/* line 1645, ../sass/_main.scss */
.form-control.textarea-new.inversor:focus {
  border-color: #c9a75d;
}
/* line 1648, ../sass/_main.scss */
.form-control.textarea-new.inversor:active {
  border-color: #c9a75d;
}
/* line 1651, ../sass/_main.scss */
.form-control.textarea-new.inversor.error {
  border-color: red;
}
/* line 1656, ../sass/_main.scss */
.form-control.textarea-new.afiliado:focus {
  border-color: #002c3e;
}
/* line 1659, ../sass/_main.scss */
.form-control.textarea-new.afiliado:active {
  border-color: #002c3e;
}
/* line 1662, ../sass/_main.scss */
.form-control.textarea-new.afiliado.error {
  border-color: red;
}
/* line 1668, ../sass/_main.scss */
.form-control.select-new:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
}
/* line 1675, ../sass/_main.scss */
.form-control.select-new:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ededed;
}
/* line 1683, ../sass/_main.scss */
.form-control.select-new.prestatario:focus {
  border-color: #93abcf;
}
/* line 1686, ../sass/_main.scss */
.form-control.select-new.prestatario:active {
  border-color: #93abcf;
}
/* line 1689, ../sass/_main.scss */
.form-control.select-new.prestatario.error {
  border-color: red;
}
/* line 1694, ../sass/_main.scss */
.form-control.select-new.inversor:focus {
  border-color: #c9a75d;
}
/* line 1697, ../sass/_main.scss */
.form-control.select-new.inversor:active {
  border-color: #c9a75d;
}
/* line 1700, ../sass/_main.scss */
.form-control.select-new.inversor.error {
  border-color: red;
}
/* line 1705, ../sass/_main.scss */
.form-control.select-new.afiliado:focus {
  border-color: #002c3e;
}
/* line 1708, ../sass/_main.scss */
.form-control.select-new.afiliado:active {
  border-color: #002c3e;
}
/* line 1711, ../sass/_main.scss */
.form-control.select-new.afiliado.error {
  border-color: red;
}
/* line 1716, ../sass/_main.scss */
.form-control :disabled, .form-control [disabled], .form-control fieldset[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* line 1722, ../sass/_main.scss */
.radioinvisible {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/* line 1728, ../sass/_main.scss */
.label-form {
  font-family: "Noto Sans", "sans-serif";
  font-style: italic;
  color: #555;
  font-size: 13px;
  background: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
}
/* line 1739, ../sass/_main.scss */
.label-form label {
  margin-right: 5px;
  margin-left: 5px;
  font-weight: 400;
}

/* line 1745, ../sass/_main.scss */
.addon-solo {
  background-color: #e4e4e4;
  padding: 4px 6px;
}

/* line 1749, ../sass/_main.scss */
.select-estilo {
  background: url("/img/new-arrows.png") no-repeat right top #ffffff;
  border: none;
}
/* line 1752, ../sass/_main.scss */
.select-estilo select {
  background: transparent;
  line-height: 1.3;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}
/* line 1763, ../sass/_main.scss */
.select-estilo select::-ms-expand {
  display: none;
}

/* line 1767, ../sass/_main.scss */
.input-group-addon {
  color: #555;
  font-family: "Noto Sans", "sans-serif";
  border: none;
  border-bottom: 1px solid #898989;
  border-bottom-right-radius: 0;
  background: none;
  padding: 6px 0;
}

/* line 1777, ../sass/_main.scss */
.placeholder {
  color: #adadad;
}

/* line 1780, ../sass/_main.scss */
.placeholder-color {
  margin-top: 0px;
  width: 300px;
  height: 27px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: 1px solid white;
  padding: 0 10px;
}

/* line 1790, ../sass/_main.scss */
.placeholder-color::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9) !important;
}

/* line 1793, ../sass/_main.scss */
.input-navbar {
  background: none !important;
  border: none;
  border-bottom: 1px solid #ffffff;
  padding-left: 7px;
  margin: 10px 12px 0 0;
  font-family: "Noto Sans", "sans-serif";
  font-style: italic;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
  box-shadow: 0 3px 1px -2px rgba(160, 160, 160, 0.4);
  color: #ffffff !important;
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
  outline: none;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
/* line 1819, ../sass/_main.scss */
.input-navbar :-webkit-autofill {
  background: none !important;
  background-color: transparent !important;
  background-image: none !important;
  color: #000000 !important;
}

/* line 1826, ../sass/_main.scss */
.input-search {
  width: 300px;
}

/* line 1830, ../sass/_main.scss */
.form-login-navbar input::-webkit-input-placeholder {
  color: #ffffff !important;
}
/* line 1833, ../sass/_main.scss */
.form-login-navbar input::-moz-placeholder {
  color: #ffffff !important;
}
/* line 1836, ../sass/_main.scss */
.form-login-navbar input::-ms-input-placeholder {
  color: #ffffff !important;
}
/* line 1839, ../sass/_main.scss */
.form-login-navbar input:placeholder-shown {
  color: #ffffff !important;
}

/* line 1844, ../sass/_main.scss */
.category-type {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: gainsboro;
  margin: 0 auto;
}
/* line 1850, ../sass/_main.scss */
.category-type > .iconocategoria {
  border-radius: 50%;
  position: relative;
  top: 36px;
}

/* line 1860, ../sass/_main.scss */
.circulo-respuesta {
  vertical-align: top;
  width: 67px;
  height: 67px;
  margin: 0 auto;
  line-height: 67px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background-size: 67px 67px;
  border: 2px solid #cccccc;
}
/* line 1873, ../sass/_main.scss */
.circulo-respuesta.prestatario:hover, .circulo-respuesta.prestatario:focus, .circulo-respuesta.prestatario:active, .circulo-respuesta.prestatario.activo {
  background: #009bae;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #009bae 0%, #009bae 57%, #ffffff 59%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, #009bae 0%, #009bae 57%, #ffffff 59%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #009bae 0%, #009bae 57%, #ffffff 59%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color_prestatario', endColorstr='#ffffff',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  border: 2px solid #cccccc;
}
/* line 1884, ../sass/_main.scss */
.circulo-respuesta.prestatario:hover.screen, .circulo-respuesta.prestatario:focus.screen, .circulo-respuesta.prestatario:active.screen, .circulo-respuesta.prestatario.activo.screen {
  background: #009bae !important;
}
/* line 1888, ../sass/_main.scss */
.circulo-respuesta.prestatario .fa {
  color: #ffffff;
  margin: 22px;
  font-size: 23px;
}
@media (max-width: 767px) {
  /* line 1872, ../sass/_main.scss */
  .circulo-respuesta.prestatario {
    width: 90%;
    height: 45px;
    background-color: #ffffff;
    border: 2px solid #009bae;
    color: #009bae;
    font-size: 14px;
    font-family: "Lato", "sans-serif";
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px 30px;
    outline: none;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }
  /* line 1922, ../sass/_main.scss */
  .circulo-respuesta.prestatario:hover, .circulo-respuesta.prestatario:focus, .circulo-respuesta.prestatario:active, .circulo-respuesta.prestatario.activo {
    background: #009bae;
    color: white;
    outline: none;
    border: 2px solid #009bae;
  }
}
/* line 1932, ../sass/_main.scss */
.circulo-respuesta.afiliado:hover, .circulo-respuesta.afiliado:focus, .circulo-respuesta.afiliado:active, .circulo-respuesta.afiliado.activo {
  background: #002c3e;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #002c3e 0%, #002c3e 57%, #ffffff 59%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, #002c3e 0%, #002c3e 57%, #ffffff 59%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #002c3e 0%, #002c3e 57%, #ffffff 59%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color_afiliado', endColorstr='#ffffff',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  border: 2px solid #cccccc;
}
/* line 1943, ../sass/_main.scss */
.circulo-respuesta.afiliado:hover.screen, .circulo-respuesta.afiliado:focus.screen, .circulo-respuesta.afiliado:active.screen, .circulo-respuesta.afiliado.activo.screen {
  background: #002c3e !important;
}
/* line 1947, ../sass/_main.scss */
.circulo-respuesta.afiliado .fa {
  color: #ffffff;
  margin: 22px;
  font-size: 23px;
}
@media (max-width: 767px) {
  /* line 1931, ../sass/_main.scss */
  .circulo-respuesta.afiliado {
    width: 90%;
    height: 45px;
    background-color: #ffffff;
    border: 2px solid #002c3e;
    color: #002c3e;
    font-size: 14px;
    font-family: "Lato", "sans-serif";
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px 30px;
    outline: none;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }
  /* line 1981, ../sass/_main.scss */
  .circulo-respuesta.afiliado:hover, .circulo-respuesta.afiliado:focus, .circulo-respuesta.afiliado:active, .circulo-respuesta.afiliado.activo {
    background: #002c3e;
    color: white;
    outline: none;
    border: 2px solid #002c3e;
  }
}

/* line 1995, ../sass/_main.scss */
.texto-respuesta {
  margin: 10px auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: #777774;
}

/* line 2004, ../sass/_main.scss */
.col-std {
  margin: 0 auto;
  text-align: center;
}

/* line 2008, ../sass/_main.scss */
.col-top-box {
  margin: 0 auto;
  text-align: left;
  background-color: #ffffff;
  height: 100px;
  padding: 0 25px 0 0;
}

/* line 2015, ../sass/_main.scss */
.col-box {
  margin: 0 auto;
  text-align: left;
  border-left: 15px solid #ffffff;
  border-right: 15px solid #ffffff;
  height: 330px;
}

/* line 2022, ../sass/_main.scss */
.col-bottom-box {
  margin: 0 auto;
  background-color: #ffffff;
  text-align: left;
  padding: 50px 15px;
  min-height: 480px;
}

/* line 2029, ../sass/_main.scss */
.col-box-interno {
  height: 0;
  border-left: 15px solid #ffffff;
  border-right: 15px solid #ffffff;
  box-shadow: inset 0 0 0 10px #000000;
}

/* line 2036, ../sass/_main.scss */
.col-bottom-box-interno {
  padding: 20px 15px 35px;
}

/* line 2039, ../sass/_main.scss */
.col-box-landing {
  background-size: cover;
  min-height: 400px;
  height: auto;
}

/* line 2044, ../sass/_main.scss */
.col-box-landing-vivienda {
  background-image: url("/img/landing-vivienda2-hero.jpg");
}

/* line 2047, ../sass/_main.scss */
.col-box-landing-promocion {
  background-image: url("/img/landing-promocion-hero2.jpg");
}

/* line 2050, ../sass/_main.scss */
.col-box-landing-emagister {
  background-image: url("/img/landing-estudios-hero2.jpg");
}

/* line 2053, ../sass/_main.scss */
.col-box-landing-educaweb {
  background-image: url("/img/landing-estudios2-hero.jpg");
}

/* line 2056, ../sass/_main.scss */
.col-box-landing-boda {
  background-image: url("/img/landing-boda-hero.jpg");
}

/* line 2059, ../sass/_main.scss */
.col-box-landing-bicicleta {
  background-image: url("/img/landing-bicicleta-hero.jpg");
}

/* line 2062, ../sass/_main.scss */
.col-box-landing-viaje {
  background-image: url("/img/landing-viaje-hero.jpg");
}

/* line 2066, ../sass/_main.scss */
.col-std-landing {
  padding-top: 50px;
}

/* line 2069, ../sass/_main.scss */
.cuadro-testimonios {
  padding: 10px;
}

/* line 2072, ../sass/_main.scss */
.bloque-testimonios {
  height: 114px;
}

/* line 2075, ../sass/_main.scss */
.col-medidas {
  text-align: left;
  border: 1px solid #dddddd;
  background-color: #f3f3f3;
  box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.15) inset;
  padding-bottom: 50px;
}

/* line 2083, ../sass/_main.scss */
.panel-gris-landing {
  background-color: #ededed;
  margin: 26px;
  border-radius: 7px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

/* line 2091, ../sass/_main.scss */
.container-landing {
  padding-top: 50px;
}

/* line 2094, ../sass/_main.scss */
.kpi_panel {
  font-family: "Lato", "sans-serif";
  color: #434343;
  font-size: 34px;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  padding: 40px 0;
  text-transform: capitalize;
}
/* line 2103, ../sass/_main.scss */
.kpi_panel.kpi_panel_sm {
  font-size: 26px;
  padding: 20px 0;
}
/* line 2107, ../sass/_main.scss */
.kpi_panel.kpi_panel_xs {
  font-size: 16px;
  padding: 20px 0;
}

/* line 2112, ../sass/_main.scss */
.kpi_monedero {
  padding: 12px 0;
}

/* line 2115, ../sass/_main.scss */
.lista-tareas {
  padding: 0;
  margin: 0;
}

/* line 2119, ../sass/_main.scss */
.detalle-monedero {
  text-align: right;
  margin-bottom: 10px;
  font-size: 12px;
  color: #000000;
}
/* line 2125, ../sass/_main.scss */
.detalle-monedero tbody > tr > td {
  padding: 2px;
  border-top: 1px solid #000000;
}
/* line 2129, ../sass/_main.scss */
.detalle-monedero tbody > tr:first-child > td {
  padding: 2px;
  border-top: 2px solid #000000;
}
/* line 2133, ../sass/_main.scss */
.detalle-monedero tbody > tr:last-child > td {
  padding: 2px;
  border-bottom: 2px solid #000000;
}
/* line 2138, ../sass/_main.scss */
.detalle-monedero thead > tr > td {
  padding: 2px;
  border-bottom: 2px solid #000000;
}

/* line 2143, ../sass/_main.scss */
.ayuda-solicitud {
  font-size: 11px;
  background-color: transparent;
  padding: 0 4px;
  margin: 0 15px 0 0;
  position: absolute;
  z-index: 5;
}

/* line 2151, ../sass/_main.scss */
.well-new {
  border-radius: 0;
  border: 1px solid #000000;
}

/* line 2156, ../sass/_main.scss */
.iconoActivo {
  color: #ffffff;
  text-shadow: 0 0 22px #ffffff;
}

/* line 2161, ../sass/_main.scss */
.blog-post {
  margin-top: 30px;
}

/* line 2164, ../sass/_main.scss */
.div-index-main {
  margin: 0 auto;
  text-align: center;
  position: absolute;
}

/* line 2169, ../sass/_main.scss */
.div-index-inv {
  -webkit-transition: box-shadow ease-in-out .15s,background-color .15s;
  transition: box-shadow ease-in-out .15s,background-color .15s;
  margin: 0 auto;
}
/* line 2174, ../sass/_main.scss */
.div-index-inv:hover {
  background-color: rgba(0, 0, 0, 0.05);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
}

/* line 2182, ../sass/_main.scss */
.div-index-prest {
  -webkit-transition: box-shadow ease-in-out .15s,background-color .15s;
  transition: box-shadow ease-in-out .15s,background-color .15s;
  margin: 0 auto;
}
/* line 2187, ../sass/_main.scss */
.div-index-prest:hover {
  background-color: rgba(0, 0, 0, 0.05);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
}

/* line 2195, ../sass/_main.scss */
.div-carousel {
  margin: 0 auto;
}

/* line 2198, ../sass/_main.scss */
.img-inversormes {
  width: 100%;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 0;
  max-width: 61px;
}

/* line 2205, ../sass/_main.scss */
.img-pasos-public {
  width: 100%;
  padding: 50px 0;
}

/* line 2210, ../sass/_main.scss */
.foto-dni .btn-prestatario {
  /*min-height: 52px;*/
}
/* line 2212, ../sass/_main.scss */
.foto-dni .btn-prestatario p {
  white-space: initial;
  margin-bottom: 0;
  float: right;
  max-width: 82%;
}
/* line 2218, ../sass/_main.scss */
.foto-dni .btn-prestatario .btn-verificacion-sub {
  margin-bottom: 30px;
}
/* line 2220, ../sass/_main.scss */
.foto-dni .btn-prestatario .btn-verificacion-sub .camera-circle {
  font-size: 8em;
  border: 2px solid #ffffff;
  color: #ffffff;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  margin: auto;
}
/* line 2229, ../sass/_main.scss */
.foto-dni .btn-prestatario .btn-verificacion-sub .camera-circle .camera-middle {
  vertical-align: bottom;
  text-shadow: none;
}
/* line 2237, ../sass/_main.scss */
.foto-dni .frontal-image-post, .foto-dni .trasera-image-post {
  display: none;
  width: 100%;
  margin-top: 20px;
}
/* line 2242, ../sass/_main.scss */
.foto-dni .btn-inversor {
  min-height: 52px;
}
/* line 2244, ../sass/_main.scss */
.foto-dni .btn-inversor p {
  white-space: initial;
  margin-bottom: 0;
  float: right;
  max-width: 83%;
}
/* line 2250, ../sass/_main.scss */
.foto-dni .btn-inversor .btn-verificacion-sub {
  margin-bottom: 30px;
}
/* line 2252, ../sass/_main.scss */
.foto-dni .btn-inversor .btn-verificacion-sub .camera-circle {
  font-size: 8em;
  border: 2px solid #ffffff;
  color: #ffffff;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  margin: auto;
}
/* line 2261, ../sass/_main.scss */
.foto-dni .btn-inversor .btn-verificacion-sub .camera-circle .camera-middle {
  vertical-align: bottom;
  text-shadow: none;
}
/* line 2269, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal {
  display: none;
  position: relative;
  min-height: 322px;
  height: 100%;
  margin-top: 30px;
  width: 429px;
}
@media (max-width: 991px) {
  /* line 2269, ../sass/_main.scss */
  .foto-dni .make-picture-dni-frontal {
    width: 326px;
  }
}
/* line 2281, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal #webcam-frontal {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
/* line 2292, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal #webcam-frontal video {
  width: 100% !important;
}
/* line 2296, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal #canvas-frontal {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  max-width: 429px;
}
/* line 2304, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  width: 429px;
  background: rgba(0, 0, 0, 0.5);
  padding: 1em 0;
}
@media (max-width: 991px) {
  /* line 2304, ../sass/_main.scss */
  .foto-dni .make-picture-dni-frontal .snap-shot {
    width: 326px;
    left: 0;
  }
}
/* line 2318, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot.complete {
  height: 100%;
}
/* line 2321, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .btn-shot-picture {
  background: #ffffff;
  width: 50px;
  text-indent: -99999px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  margin: auto;
  position: relative;
}
/* line 2331, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .btn-shot-picture:hover {
  background-color: #d3d3d3;
}
/* line 2334, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .btn-shot-picture .circulo-interior {
  border: 2px solid #4285f4;
  border-radius: 50px;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 3px;
  left: 3px;
}
/* line 2345, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .btn-cancel-picture {
  display: none;
  background: #ffffff;
  width: 75px;
  height: 33px;
  border-radius: 3px;
  cursor: pointer;
  margin: auto;
  position: absolute;
  top: 22px;
  left: 17%;
}
/* line 2356, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .btn-cancel-picture .cancel-interior {
  font-size: 19px;
  color: #737373;
  position: absolute;
  top: 3px;
  left: 7px;
}
/* line 2364, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .informacion {
  font-size: 15px;
  color: #ffffff;
  margin: auto;
  text-align: center;
  margin-top: 3em;
  text-transform: uppercase;
}
/* line 2371, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .informacion i {
  font-size: 10em;
}
/* line 2374, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .informacion p {
  margin-top: 25px;
}
/* line 2378, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .btn-accept-picture {
  display: none;
  background: #009bae;
  width: 86px;
  height: 50px;
  border-radius: 2px;
  cursor: pointer;
  margin: auto;
  position: relative;
  border: 3px solid #009bae;
}
/* line 2388, ../sass/_main.scss */
.foto-dni .make-picture-dni-frontal .snap-shot .btn-accept-picture .tick-interior {
  font-size: 22px;
  color: #ffffff;
  position: absolute;
  top: 6px;
  left: 10px;
}
/* line 2398, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera {
  display: none;
  position: relative;
  min-height: 322px;
  height: 100%;
  margin-top: 30px;
  width: 429px;
}
@media (max-width: 991px) {
  /* line 2398, ../sass/_main.scss */
  .foto-dni .make-picture-dni-trasera {
    width: 326px;
  }
}
/* line 2408, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera #webcam-trasera {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
/* line 2419, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera #webcam-trasera video {
  width: 100% !important;
}
/* line 2423, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera #canvas-trasera {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  max-width: 429px;
}
/* line 2431, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  width: 429px;
  background: rgba(0, 0, 0, 0.5);
  padding: 1em 0;
}
@media (max-width: 991px) {
  /* line 2431, ../sass/_main.scss */
  .foto-dni .make-picture-dni-trasera .snap-shot {
    width: 326px;
    left: 0;
  }
}
/* line 2445, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot.complete {
  height: 100%;
}
/* line 2448, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .btn-shot-picture {
  background: #ffffff;
  width: 50px;
  text-indent: -99999px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  margin: auto;
  position: relative;
}
/* line 2458, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .btn-shot-picture:hover {
  background-color: #d3d3d3;
}
/* line 2461, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .btn-shot-picture .circulo-interior {
  border: 2px solid #4285f4;
  border-radius: 50px;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 3px;
  left: 3px;
}
/* line 2472, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .btn-cancel-picture {
  display: none;
  background: #ffffff;
  width: 75px;
  height: 33px;
  border-radius: 3px;
  cursor: pointer;
  margin: auto;
  position: absolute;
  top: 22px;
  left: 17%;
}
/* line 2483, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .btn-cancel-picture .cancel-interior {
  font-size: 19px;
  color: #737373;
  position: absolute;
  top: 3px;
  left: 7px;
}
/* line 2491, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .informacion {
  font-size: 15px;
  color: #ffffff;
  margin: auto;
  text-align: center;
  margin-top: 3em;
  text-transform: uppercase;
}
/* line 2498, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .informacion i {
  font-size: 10em;
}
/* line 2501, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .informacion p {
  margin-top: 25px;
}
/* line 2505, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .btn-accept-picture {
  display: none;
  background: #009bae;
  width: 86px;
  height: 50px;
  border-radius: 2px;
  cursor: pointer;
  margin: auto;
  position: relative;
  border: 3px solid #009bae;
}
/* line 2515, ../sass/_main.scss */
.foto-dni .make-picture-dni-trasera .snap-shot .btn-accept-picture .tick-interior {
  font-size: 22px;
  color: #ffffff;
  position: absolute;
  top: 6px;
  left: 10px;
}
/* line 2525, ../sass/_main.scss */
.foto-dni .imagen-frontal {
  max-width: 429px;
  max-height: 322px;
}
/* line 2529, ../sass/_main.scss */
.foto-dni .imagen-trasera {
  max-width: 429px;
  max-height: 322px;
}
/* line 2533, ../sass/_main.scss */
.foto-dni .imagen-pasaporte {
  max-width: 429px;
  max-height: 322px;
}
/* line 2537, ../sass/_main.scss */
.foto-dni input {
  display: none;
}
/* line 2541, ../sass/_main.scss */
.foto-dni .mobile-check-ok {
  height: 100%;
  font-size: 21px;
  vertical-align: middle;
  float: left;
}
/* line 2546, ../sass/_main.scss */
.foto-dni .mobile-check-ok.prestatario {
  color: #009bae;
}
/* line 2549, ../sass/_main.scss */
.foto-dni .mobile-check-ok.inversor {
  color: #009bae;
}
/* line 2552, ../sass/_main.scss */
.foto-dni .mobile-check-ok.afiliado {
  color: #002c3e;
}
/* line 2556, ../sass/_main.scss */
.foto-dni .rectangulo-foto-dni {
  border: 3px dashed #ffffff;
  width: 74%;
  height: 62.18%;
  position: absolute;
  z-index: 3;
  left: 51px;
  top: 43px;
}
/* line 2565, ../sass/_main.scss */
.foto-dni a.btn-inversor {
  padding-top: 16px;
}

/* line 2569, ../sass/_main.scss */
.popover-content {
  padding: 3px;
}
/* line 2571, ../sass/_main.scss */
.popover-content .subir {
  display: block;
  margin: 0;
  width: 100%;
  min-width: 200px;
  margin-top: 5px;
}
/* line 2578, ../sass/_main.scss */
.popover-content .hacer {
  display: block;
  margin: 0;
  width: 100%;
  min-width: 200px;
}

/* line 2585, ../sass/_main.scss */
.rectangulo-webcam-foto {
  width: 78%;
  height: 63.1%;
  border: 3px dashed #ffffff;
  position: absolute;
  z-index: 4;
  top: 29px;
  left: 48px;
}

/* line 2595, ../sass/_main.scss */
.table.simulador {
  font-weight: 300;
  font-size: 18px;
}
/* line 2599, ../sass/_main.scss */
.table.simulador tbody tr:first-child {
  border-top: none;
}
/* line 2601, ../sass/_main.scss */
.table.simulador tbody tr:first-child td {
  font-weight: 300;
  border: none;
}
/* line 2606, ../sass/_main.scss */
.table.simulador tbody tr {
  border-top: 1px solid #ddd;
}
/* line 2608, ../sass/_main.scss */
.table.simulador tbody tr td {
  font-weight: 300;
  border: none;
}
/* line 2613, ../sass/_main.scss */
.table.simulador tbody tr:last-child {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
/* line 2621, ../sass/_main.scss */
.table.tabla-basica > thead > tr > th {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 8px 5px;
}
/* line 2627, ../sass/_main.scss */
.table.tabla-basica > thead:first-child > tr:first-child > th {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 8px 5px;
}
/* line 2632, ../sass/_main.scss */
.table.tabla-basica > thead:first-child > tr:first-child > td {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 8px 5px;
}
/* line 2640, ../sass/_main.scss */
.table.tabla-basica > caption + thead > tr:first-child > th {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 8px 5px;
}
/* line 2645, ../sass/_main.scss */
.table.tabla-basica > caption + thead > tr:first-child > td {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 8px 5px;
}
/* line 2652, ../sass/_main.scss */
.table.tabla-basica > colgroup + thead > tr:first-child > th {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 8px 5px;
}
/* line 2657, ../sass/_main.scss */
.table.tabla-basica > colgroup + thead > tr:first-child > td {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 8px 5px;
}
/* line 2663, ../sass/_main.scss */
.table.tabla-basica > tbody > tr > td {
  border-bottom: 1px solid #000000;
  border-top: 0;
  padding: 8px 5px;
}
/* line 2667, ../sass/_main.scss */
.table.tabla-basica > tbody > tr > td.tabla-tipo {
  color: #000000;
  font-size: 28px;
  font-weight: 900;
  border-bottom: 1px solid #000000;
}
/* line 2677, ../sass/_main.scss */
.table > thead > tr > td.primary {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2680, ../sass/_main.scss */
.table > thead > tr > td.primary:hover {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2685, ../sass/_main.scss */
.table > thead > tr > td.primary2:hover {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2691, ../sass/_main.scss */
.table > thead > tr > th.primary {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2694, ../sass/_main.scss */
.table > thead > tr > th.primary:hover {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2699, ../sass/_main.scss */
.table > thead > tr > th.primary2 {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2702, ../sass/_main.scss */
.table > thead > tr > th.primary2:hover {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2709, ../sass/_main.scss */
.table > thead > tr.primary > td {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2713, ../sass/_main.scss */
.table > thead > tr.primary > th {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2718, ../sass/_main.scss */
.table > thead > tr.primary:hover > td {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2722, ../sass/_main.scss */
.table > thead > tr.primary:hover > th {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2729, ../sass/_main.scss */
.table > thead > tr.primary2 > td {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2733, ../sass/_main.scss */
.table > thead > tr.primary2 > th {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2738, ../sass/_main.scss */
.table > thead > tr.primary2:hover > td {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2742, ../sass/_main.scss */
.table > thead > tr.primary2:hover > th {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2751, ../sass/_main.scss */
.table > tbody > tr > td.primary {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2754, ../sass/_main.scss */
.table > tbody > tr > td.primary:hover {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2759, ../sass/_main.scss */
.table > tbody > tr > td.primary2 {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2762, ../sass/_main.scss */
.table > tbody > tr > td.primary2:hover {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2769, ../sass/_main.scss */
.table > tbody > tr > th.primary {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2772, ../sass/_main.scss */
.table > tbody > tr > th.primary:hover {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2777, ../sass/_main.scss */
.table > tbody > tr > th.primary2 {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2780, ../sass/_main.scss */
.table > tbody > tr > th.primary2:hover {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2787, ../sass/_main.scss */
.table > tbody > tr.primary > td {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2791, ../sass/_main.scss */
.table > tbody > tr.primary > th {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2796, ../sass/_main.scss */
.table > tbody > tr.primary:hover > td {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2800, ../sass/_main.scss */
.table > tbody > tr.primary:hover > th {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2807, ../sass/_main.scss */
.table > tbody > tr.primary2 > td {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2811, ../sass/_main.scss */
.table > tbody > tr.primary2 > th {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2816, ../sass/_main.scss */
.table > tbody > tr.primary2:hover > td {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2820, ../sass/_main.scss */
.table > tbody > tr.primary2:hover > th {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2829, ../sass/_main.scss */
.table > tfoot > tr > td.primary {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2832, ../sass/_main.scss */
.table > tfoot > tr > td.primary:hover {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2837, ../sass/_main.scss */
.table > tfoot > tr > td.primary2 {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2840, ../sass/_main.scss */
.table > tfoot > tr > td.primary2:hover {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2847, ../sass/_main.scss */
.table > tfoot > tr > th.primary {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2850, ../sass/_main.scss */
.table > tfoot > tr > th.primary:hover {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2855, ../sass/_main.scss */
.table > tfoot > tr > th.primary2 {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2858, ../sass/_main.scss */
.table > tfoot > tr > th.primary2:hover {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2865, ../sass/_main.scss */
.table > tfoot > tr.primary > td {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2869, ../sass/_main.scss */
.table > tfoot > tr.primary > th {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2874, ../sass/_main.scss */
.table > tfoot > tr.primary:hover > td {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2878, ../sass/_main.scss */
.table > tfoot > tr.primary:hover > th {
  background-color: rgba(204, 160, 85, 0.32);
  border-color: rgba(204, 160, 85, 0.32);
}
/* line 2885, ../sass/_main.scss */
.table > tfoot > tr.primary2 > td {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2889, ../sass/_main.scss */
.table > tfoot > tr.primary2 > th {
  background-color: rgba(204, 160, 85, 0.13);
  border-color: rgba(204, 160, 85, 0.13);
}
/* line 2894, ../sass/_main.scss */
.table > tfoot > tr.primary2:hover > td {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}
/* line 2898, ../sass/_main.scss */
.table > tfoot > tr.primary2:hover > th {
  background-color: rgba(204, 160, 85, 0.23);
  border-color: rgba(204, 160, 85, 0.23);
}

/* line 2907, ../sass/_main.scss */
.linea-tabla {
  border-bottom: 1px solid black;
  border-top: 0;
}

/* line 2913, ../sass/_main.scss */
.tabla-basica-completa > tbody > tr > td {
  padding: 6px 10px;
  border: 1px solid #000000;
}

/* line 2918, ../sass/_main.scss */
.tabla-faltan {
  white-space: nowrap;
}

/* line 2921, ../sass/_main.scss */
.table-responsive {
  border: none;
}

/* line 2924, ../sass/_main.scss */
.table-sm {
  text-align: left;
  margin-bottom: 10px;
  font-size: 12px;
}

/* line 2930, ../sass/_main.scss */
.tablaemail {
  background-color: #f6f6f6;
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  border-left: 5px solid #002c3e;
}

/* line 2938, ../sass/_main.scss */
.titulo-tabla-estadisticas {
  background-color: #f4f4f4;
}

/* line 2941, ../sass/_main.scss */
.contenedor-tabla-prestamos {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  color: #000000;
}
/* line 2946, ../sass/_main.scss */
.contenedor-tabla-prestamos table {
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
/* line 2953, ../sass/_main.scss */
.contenedor-tabla-prestamos select {
  margin-bottom: 0;
}
/* line 2956, ../sass/_main.scss */
.contenedor-tabla-prestamos .collapse.in {
  display: table-row;
}
/* line 2959, ../sass/_main.scss */
.contenedor-tabla-prestamos .table tbody > tr > td {
  vertical-align: middle;
}

/* line 2963, ../sass/_main.scss */
.zonagris {
  background-color: #eeeeee;
  padding: 20px 15px;
  border-top: 3px solid #002c3e;
}
/* line 2967, ../sass/_main.scss */
.zonagris.afiliado {
  border-top-color: #002c3e;
}

/* line 2974, ../sass/_main.scss */
.docs-galley ul.docs-pictures {
  list-style: none;
  padding: 0;
}
/* line 2977, ../sass/_main.scss */
.docs-galley ul.docs-pictures li {
  max-width: 65px;
  display: inline-block;
  margin-right: 5px;
}
/* line 2981, ../sass/_main.scss */
.docs-galley ul.docs-pictures li img {
  width: 100%;
}

/* line 2987, ../sass/_main.scss */
.tabla-sinborde {
  border: none;
}
/* line 2989, ../sass/_main.scss */
.tabla-sinborde > thead > tr > th {
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
}
/* line 2993, ../sass/_main.scss */
.tabla-sinborde > tbody > tr > td {
  border-bottom: 1px solid #000000;
  border-top: 0;
}

/* line 2998, ../sass/_main.scss */
.pagination {
  border-radius: 0;
}
/* line 3001, ../sass/_main.scss */
.pagination .current {
  border: 1px solid #000000;
  color: #ffffff;
  cursor: default;
  background-color: #009bae;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
}
/* line 3014, ../sass/_main.scss */
.pagination.pagination-sm .current {
  border: 1px solid #000000;
  color: #ffffff;
  cursor: default;
  background-color: #009bae;
  position: relative;
  float: left;
  padding: 2px 8px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
}
/* line 3026, ../sass/_main.scss */
.pagination.pagination-sm .first > a {
  position: relative;
  float: left;
  padding: 2px 8px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3037, ../sass/_main.scss */
.pagination.pagination-sm .page > a {
  position: relative;
  float: left;
  padding: 2px 8px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3048, ../sass/_main.scss */
.pagination.pagination-sm .next > a {
  position: relative;
  float: left;
  padding: 2px 8px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3059, ../sass/_main.scss */
.pagination.pagination-sm .previous > a {
  position: relative;
  float: left;
  padding: 2px 8px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3070, ../sass/_main.scss */
.pagination.pagination-sm .last > a {
  position: relative;
  float: left;
  padding: 2px 8px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3082, ../sass/_main.scss */
.pagination .first > a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3092, ../sass/_main.scss */
.pagination .first > a:hover {
  background-color: #f5f5f5;
}
/* line 3096, ../sass/_main.scss */
.pagination .page > a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3106, ../sass/_main.scss */
.pagination .page > a:hover {
  background-color: #f5f5f5;
}
/* line 3110, ../sass/_main.scss */
.pagination .next > a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3120, ../sass/_main.scss */
.pagination .next > a:hover {
  background-color: #f5f5f5;
}
/* line 3124, ../sass/_main.scss */
.pagination .previous > a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3134, ../sass/_main.scss */
.pagination .previous > a:hover {
  background-color: #f5f5f5;
}
/* line 3138, ../sass/_main.scss */
.pagination .last > a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
/* line 3148, ../sass/_main.scss */
.pagination .last > a:hover {
  background-color: #f5f5f5;
}
/* line 3153, ../sass/_main.scss */
.pagination > .paginate_button.previous > a {
  border-radius: 0;
  border: 1px solid #000000;
}
/* line 3157, ../sass/_main.scss */
.pagination > .paginate_button.previous > a:hover {
  border-radius: 0;
  border: 1px solid #000000;
  background-color: #f5f5f5;
}
/* line 3164, ../sass/_main.scss */
.pagination > .paginate_button.next > a {
  border-radius: 0;
  border: 1px solid #000000;
}
/* line 3168, ../sass/_main.scss */
.pagination > .paginate_button.next > a:hover {
  border-radius: 0;
  border: 1px solid #000000;
  background-color: #f5f5f5;
}

/* line 3177, ../sass/_main.scss */
.score-lg {
  font-weight: 500;
  font-size: 30px;
  padding: 7px 16px;
  height: 50px;
  width: 50px;
  text-transform: capitalize;
}

/* line 3185, ../sass/_main.scss */
.score-md {
  font-weight: 500;
  font-size: 20px;
  padding: 7px 12px;
  height: 30px;
  width: 30px;
  text-transform: capitalize;
}

/* line 3193, ../sass/_main.scss */
.score-sm {
  font-weight: 500;
  font-size: 12px;
  padding: 4px 7px;
  height: 30px;
  width: 30px;
  text-transform: capitalize;
}

/* line 3201, ../sass/_main.scss */
.score-xs {
  font-weight: 500;
  font-size: 12px;
  padding: 1px 4px;
  height: 30px;
  width: 30px;
  text-transform: capitalize;
}

/* line 3209, ../sass/_main.scss */
.score-index {
  color: #A69457;
  background-color: #E9DCAD;
}

/* line 3214, ../sass/_main.scss */
.scoreA\+\+ {
  color: #ffffff;
  background-color: #5b90b8;
}

/* line 3218, ../sass/_main.scss */
.scoreA\+ {
  color: #ffffff;
  background-color: #5b90b8;
}

/* line 3222, ../sass/_main.scss */
.scoreA {
  color: #ffffff;
  background-color: #5b90b8;
}

/* line 3226, ../sass/_main.scss */
.scoreB\+ {
  color: #ffffff;
  background-color: #7095a9;
}

/* line 3230, ../sass/_main.scss */
.scoreB {
  color: #ffffff;
  background-color: #7095a9;
}

/* line 3234, ../sass/_main.scss */
.scoreC\+ {
  color: #ffffff;
  background-color: #8a9a97;
}

/* line 3238, ../sass/_main.scss */
.scoreC {
  color: #ffffff;
  background-color: #8a9a97;
}

/* line 3242, ../sass/_main.scss */
.scoreD {
  color: #ffffff;
  background-color: #a89f81;
}

/* line 3246, ../sass/_main.scss */
.scoreE {
  color: #ffffff;
  background-color: #cfb06e;
}

/* line 3250, ../sass/_main.scss */
.scoreF {
  color: #ffffff;
  background-color: #d8a95f;
}

/* line 3254, ../sass/_main.scss */
.borde-dash {
  padding: 15px 3px;
  background: #eff8ff;
}

/* line 3258, ../sass/_main.scss */
.borde-dash-verde {
  padding: 15px 3px;
  background: #e3efeb;
}

/* line 3262, ../sass/_main.scss */
.radiobuttons2 {
  width: 50%;
}

/* line 3265, ../sass/_main.scss */
.radiobuttons4 {
  width: 25%;
}

/* line 3268, ../sass/_main.scss */
.radiobuttons6 {
  width: 16.66%;
}

/* line 3271, ../sass/_main.scss */
.radiobuttons8 {
  width: 12.5%;
}

/* line 3274, ../sass/_main.scss */
.back-gris {
  background-color: #b9e1f5;
}

/* line 3277, ../sass/_main.scss */
.back-verde {
  background-color: #9dc4b7;
}

/* line 3280, ../sass/_main.scss */
.radios-mensualidad {
  margin: 10px 0;
  font-size: 13px;
}

/* line 3284, ../sass/_main.scss */
.links-footer {
  font-size: 12px;
  padding-top: 3px;
}

/* line 3288, ../sass/_main.scss */
.ui-state-hover {
  cursor: pointer;
  border: none;
}

/* line 3292, ../sass/_main.scss */
.ui-state-active {
  cursor: pointer;
  border: none;
}

/* line 3296, ../sass/_main.scss */
.ui-state-focus {
  cursor: pointer;
  border: none;
}

/* line 3300, ../sass/_main.scss */
.progress {
  margin-bottom: 0;
}

/* line 3303, ../sass/_main.scss */
.clickable {
  cursor: pointer;
  padding-top: 12px;
}

/* line 3307, ../sass/_main.scss */
.media-heading {
  margin: 0;
}

/* line 3311, ../sass/_main.scss */
.media .img-small {
  width: 50px;
}
/* line 3314, ../sass/_main.scss */
.media .img-md {
  width: 100px;
}
/* line 3317, ../sass/_main.scss */
.media .pull-left {
  padding-right: 0;
}

/* line 3322, ../sass/_main.scss */
.contenido.margensup {
  margin-top: 60px;
  margin-bottom: 60px;
}
/* line 3326, ../sass/_main.scss */
.contenido h2 {
  margin: 0;
}
/* line 3329, ../sass/_main.scss */
.contenido h3 {
  margin: 5px 0;
}

/* line 3333, ../sass/_main.scss */
.botones-monedero {
  border-spacing: 3px;
}

/* line 3336, ../sass/_main.scss */
.todo {
  width: 100%;
  height: 100%;
  color: #ffffff;
  padding-top: 0;
  text-shadow: none;
}

/* line 3344, ../sass/_main.scss */
.nav-pestanas {
  margin-bottom: 20px;
}

/* line 3347, ../sass/_main.scss */
.barra-detalleprestamo {
  margin-bottom: 20px;
  border: 1px solid #000000;
  border-radius: 0;
}

/* line 3353, ../sass/_main.scss */
.pref-inver-opciones {
  padding: 0 10px;
}

/* line 3356, ../sass/_main.scss */
fieldset {
  width: 100%;
  padding: 0;
  border: 0;
}

/* line 3363, ../sass/_main.scss */
.info-tooltip > a {
  color: #737373;
}
/* line 3365, ../sass/_main.scss */
.info-tooltip > a:hover {
  color: #333;
  text-decoration: none;
}
/* line 3370, ../sass/_main.scss */
.info-tooltip > {
    /*    .progress-bar-encobro > a:hover {
          text-decoration: none;
          > .todo {
            background-color: $color_calypso_approx;
          }
        }
        .progress-bar-refinanciada > a:hover {
          text-decoration: none;
          > .todo {
            background-color: $color_axolotl_approx;
          }
        }
        .progress-bar-info, .progress-bar-DE, .progress-bar-EC {
          > a:hover {
            text-decoration: none;
            > .todo {
              background-color: $color_tradewind_approx;
            }
          }
        }
        .progress-bar-success, .progress-bar-CO {
          > a:hover {
            text-decoration: none;
            > .todo {
              background-color: $color_goblin_approx;
            }
          }
        }
        .progress-bar-recobro, .progress-bar-CR {
          > a:hover {
            text-decoration: none;
            > .todo {
              background-color: $color_goblin_approx;
            }
          }
        }
        .progress-bar-recobroJudicial, .progress-bar-CJ {
          > a:hover {
            text-decoration: none;
            > .todo {
              background-color: $color_goblin_cj;
            }
          }
        }

        .progress-bar-recobroZank, .progress-bar-CZ {
          > a:hover {
            text-decoration: none;
            > .todo {
              background-color: $color_goblin_cz;
            }
          }
        }

        .progress-bar-warning, .progress-bar-GT, .progress-bar-RE {
          > a:hover {
            text-decoration: none;
            > .todo {
              background-color: $color_copper_approx;
            }
          }
        }
        .progress-bar-danger, .progress-bar-FA {
          > a:hover {
            text-decoration: none;
            > .todo {
              background-color: $color_sanguine_brown_approx;
            }
          }
        }
        .progress-bar-gestion > a:hover {
          text-decoration: none;
          > .todo {
            background-color: $color_chalet_green_approx;
          }
        }
        .progress-bar-actualizando > a:hover {
          text-decoration: none;
          > .todo {
            background-color: $color_chalet_actualizando;
          }
        }
        .progress-bar-devuelta > a:hover {
          text-decoration: none;
          > .todo {
            background-color: $color_devuelta_hover;
          }
        }*/
}
/* line 3372, ../sass/_main.scss */
.info-tooltip > .progress-bar-pendiente > a:hover, .info-tooltip > .progress-bar-PD > a:hover, .info-tooltip > .progress-bar-AN > a:hover, .info-tooltip > .progress-bar-encobro > a:hover, .info-tooltip > .progress-bar-refinanciada > a:hover, .info-tooltip > .progress-bar-info > a:hover, .info-tooltip > .progress-bar-DE > a:hover, .info-tooltip > .progress-bar-EC > a:hover, .info-tooltip > .progress-bar-success > a:hover, .info-tooltip > .progress-bar-CO > a:hover, .info-tooltip > .progress-bar-recobro > a:hover, .info-tooltip > .progress-bar-CR > a:hover, .info-tooltip > .progress-bar-recobroJudicial > a:hover, .info-tooltip > .progress-bar-CJ > a:hover, .info-tooltip > .progress-bar-recobroZank > a:hover, .info-tooltip > .progress-bar-provision > a:hover, .info-tooltip > .progress-bar-CZ > a:hover, .info-tooltip > .progress-bar-warning > a:hover, .info-tooltip > .progress-bar-GT > a:hover, .info-tooltip > .progress-bar-gestion > a:hover, .info-tooltip > .progress-bar-RE > a:hover, .info-tooltip > .progress-bar-danger > a:hover, .info-tooltip > .progress-bar-FA > a:hover, .info-tooltip > .progress-bar-devuelta > a:hover, .info-tooltip > .progress-bar-actualizando > a:hover {
  text-decoration: none;
}
/* line 3374, ../sass/_main.scss */
.info-tooltip > .progress-bar-pendiente > a:hover > .todo, .info-tooltip > .progress-bar-PD > a:hover > .todo, .info-tooltip > .progress-bar-AN > a:hover > .todo, .info-tooltip > .progress-bar-encobro > a:hover > .todo, .info-tooltip > .progress-bar-refinanciada > a:hover > .todo, .info-tooltip > .progress-bar-info > a:hover > .todo, .info-tooltip > .progress-bar-DE > a:hover > .todo, .info-tooltip > .progress-bar-EC > a:hover > .todo, .info-tooltip > .progress-bar-success > a:hover > .todo, .info-tooltip > .progress-bar-CO > a:hover > .todo, .info-tooltip > .progress-bar-recobro > a:hover > .todo, .info-tooltip > .progress-bar-CR > a:hover > .todo, .info-tooltip > .progress-bar-recobroJudicial > a:hover > .todo, .info-tooltip > .progress-bar-CJ > a:hover > .todo, .info-tooltip > .progress-bar-recobroZank > a:hover > .todo, .info-tooltip > .progress-bar-provision > a:hover > .todo, .info-tooltip > .progress-bar-CZ > a:hover > .todo, .info-tooltip > .progress-bar-warning > a:hover > .todo, .info-tooltip > .progress-bar-GT > a:hover > .todo, .info-tooltip > .progress-bar-gestion > a:hover > .todo, .info-tooltip > .progress-bar-RE > a:hover > .todo, .info-tooltip > .progress-bar-danger > a:hover > .todo, .info-tooltip > .progress-bar-FA > a:hover > .todo, .info-tooltip > .progress-bar-devuelta > a:hover > .todo, .info-tooltip > .progress-bar-actualizando > a:hover > .todo {
  background-color: rgba(0, 0, 0, 0.2);
}

/* line 3469, ../sass/_main.scss */
.progress-bar {
  text-shadow: 0 1px 3px black;
  vertical-align: sub;
}
/* line 3472, ../sass/_main.scss */
.progress-bar a:hover {
  text-decoration: none;
}

/* line 3476, ../sass/_main.scss */
.progress-bar-pendiente, .progress-bar-PD, .progress-bar-AN {
  background-color: #adadad;
}

/* line 3479, ../sass/_main.scss */
.progress-bar-encobro {
  background-color: #3587a7;
}

/* line 3482, ../sass/_main.scss */
.progress-bar-refinanciada, .progress-bar-RF {
  background-color: #82b482;
}

/* line 3485, ../sass/_main.scss */
.progress-bar-amortizado {
  background-color: #316731;
}

/* line 3488, ../sass/_main.scss */
.progress-bar-gestion {
  background-color: #b8b65c;
}

/* line 3491, ../sass/_main.scss */
.progress-bar-actualizando {
  background-color: #cc050f;
}

/* line 3494, ../sass/_main.scss */
.progress-bar-devuelta {
  background-color: #cec61d;
}

/* line 3497, ../sass/_main.scss */
.progress-bar-recobro {
  background-color: #458a71;
}

/* line 3500, ../sass/_main.scss */
.progress-bar-recobroZank {
  background-color: #1a8a60;
}

/* line 3503, ../sass/_main.scss */
.progress-bar-recobroJudicial {
  background-color: #008a2a;
}

/* line 3506, ../sass/_main.scss */
.progress-bar-provision {
  background-color: #4a8a89;
}

/* line 3511, ../sass/_main.scss */
a:hover > .label, a:focus > .label {
  text-decoration: none;
}

/* line 3517, ../sass/_main.scss */
.label-encobro {
  background-color: #3587a7;
}

/* line 3520, ../sass/_main.scss */
.label-refinanciada {
  background-color: #82b482;
}

/* line 3523, ../sass/_main.scss */
.label-amortizado {
  background-color: #316731;
}

/* line 3526, ../sass/_main.scss */
.label-actualizando {
  background-color: #cc050f;
}

/* line 3529, ../sass/_main.scss */
.label-gestion {
  background-color: #b8b65c;
}

/* line 3532, ../sass/_main.scss */
.label-devuelta {
  background-color: #cec61d;
}

/* line 3535, ../sass/_main.scss */
.label-recobro {
  background-color: #458a71;
}

/* line 3538, ../sass/_main.scss */
.label-recobroZank {
  background-color: #1a8a60;
}

/* line 3541, ../sass/_main.scss */
.label-recobroJudicial {
  background-color: #008a2a;
}

/* line 3546, ../sass/_main.scss */
.label-verTel {
  background-color: #cca055;
}

/* line 3549, ../sass/_main.scss */
.label-iniciada {
  background-color: #d6b074;
}

/* line 3552, ../sass/_main.scss */
.label-verBanco {
  background-color: #debf92;
}

/* line 3555, ../sass/_main.scss */
.label-est {
  background-color: #e6cfb1;
}

/* line 3558, ../sass/_main.scss */
.label-pFirma {
  background-color: #ece0d1;
}

/* line 3561, ../sass/_main.scss */
.label-dni {
  background-color: #f1f1f1;
}

/* line 3564, ../sass/_main.scss */
.label-tar {
  background-color: #d8e3df;
}

/* line 3567, ../sass/_main.scss */
.label-verIdent {
  background-color: #c0d5cd;
}

/* line 3570, ../sass/_main.scss */
.label-pTrans {
  background-color: #a7c8bc;
}

/* line 3573, ../sass/_main.scss */
.label-apr {
  background-color: #8fbaab;
}

/* line 3576, ../sass/_main.scss */
.label-de {
  background-color: #ba4f53;
}

/* line 3579, ../sass/_main.scss */
.label-ca {
  background-color: #919191;
}

/* line 3584, ../sass/_main.scss */
.label-solicitud {
  background-color: #74bad6;
}

/* line 3587, ../sass/_main.scss */
.label-preaprobado {
  background-color: #b4d48a;
}

/* line 3590, ../sass/_main.scss */
.label-analisis-filtro {
  background: linear-gradient(90deg, #74bad6 50%, #b4d48a 50%);
}

/* line 3593, ../sass/_main.scss */
.label-aprobado {
  background-color: #5cb85c;
}

/* line 3596, ../sass/_main.scss */
.label-denegado {
  background-color: #eb6060;
}

/* line 3599, ../sass/_main.scss */
.label-cancelado {
  background-color: #a8a8a8;
}

/* line 3602, ../sass/_main.scss */
.label-error {
  background-color: #ec9652;
}

/* line 3605, ../sass/_main.scss */
.label-estudio {
  background-color: #f7e463;
}

/* line 3611, ../sass/_main.scss */
.label-lightgreen {
  background-color: lightgreen;
}

/* line 3614, ../sass/_main.scss */
.label-lightcoral {
  background-color: lightcoral;
}

/* line 3617, ../sass/_main.scss */
.label-cornflowerblue {
  background-color: cornflowerblue;
}

/* line 3620, ../sass/_main.scss */
.label-lightblue {
  background-color: lightblue;
}

/* line 3623, ../sass/_main.scss */
.label-lightgray {
  background-color: #d3d3d3;
}

/* line 3626, ../sass/_main.scss */
.label-orange {
  background-color: orange;
}

/* line 3629, ../sass/_main.scss */
.label-indianred {
  background-color: #cfb06e;
}

/* line 3632, ../sass/_main.scss */
.label-plum {
  background-color: plum;
}

/* line 3635, ../sass/_main.scss */
.label-magenta {
  background-color: #964d96;
}

/* line 3638, ../sass/_main.scss */
.label-microprestamoout {
  background-color: #6e30a8;
}

/* line 3641, ../sass/_main.scss */
.label-supermercado {
  background-color: pink;
}

/* line 3644, ../sass/_main.scss */
.label-otrosingresos {
  background-color: #24e069;
}

/* line 3647, ../sass/_main.scss */
.label-transporte {
  background-color: #e0da5c;
}

/* line 3650, ../sass/_main.scss */
.label-telecom {
  background-color: #6B7A37;
}

/* line 3653, ../sass/_main.scss */
.label-ecommerce {
  background-color: #7a545f;
}

/* line 3656, ../sass/_main.scss */
.label-utilities {
  background-color: #7a7c7e;
}

/* line 3659, ../sass/_main.scss */
.label-salud {
  background-color: #bde04c;
}

/* line 3662, ../sass/_main.scss */
.label-ropa {
  background-color: #87bda8;
}

/* line 3665, ../sass/_main.scss */
.label-ocio {
  background-color: #a8ffb3;
}

/* line 3671, ../sass/_main.scss */
.label-oro {
  background-color: #daa520;
}

/* line 3674, ../sass/_main.scss */
.label-plata {
  background-color: #a0a0a0;
}

/* line 3677, ../sass/_main.scss */
.label-bronce {
  background-color: #CD7F32;
}

/* line 3680, ../sass/_main.scss */
.label-cobre {
  background-color: #b85e33;
}

/* line 3686, ../sass/_main.scss */
.label-afiliado {
  background-color: #002c3e;
}

/* line 3689, ../sass/_main.scss */
.label-prestatario {
  background-color: #009bae;
}

/* line 3692, ../sass/_main.scss */
.label-inversor {
  background-color: #009bae;
}

/* line 3696, ../sass/_main.scss */
.leyenda {
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 3px;
  font-family: "Lato", "sans-serif";
}
/* line 3703, ../sass/_main.scss */
.leyenda.disponible {
  background-color: #d9c36e;
}
/* line 3706, ../sass/_main.scss */
.leyenda.menor10 {
  background-color: #009bae;
}
/* line 3709, ../sass/_main.scss */
.leyenda.entre1015 {
  background-color: #b9663c;
}
/* line 3712, ../sass/_main.scss */
.leyenda.mayor15 {
  background-color: #6d4737;
}

/* line 3716, ../sass/_main.scss */
.pasos {
  list-style: none;
  overflow: hidden;
  margin-top: 36px;
}
/* line 3720, ../sass/_main.scss */
.pasos li {
  float: left;
}
/* line 3722, ../sass/_main.scss */
.pasos li a {
  color: #ffffff;
  text-decoration: none;
  padding: 4px 0 4px 50px;
  background: #adadad;
  position: relative;
  display: block;
  float: left;
}
/* line 3730, ../sass/_main.scss */
.pasos li a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #adadad;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
/* line 3744, ../sass/_main.scss */
.pasos li a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ffffff;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}
/* line 3759, ../sass/_main.scss */
.pasos li a:hover {
  background: #009bae;
}
/* line 3761, ../sass/_main.scss */
.pasos li a:hover:after {
  border-left-color: #009bae !important;
}
/* line 3766, ../sass/_main.scss */
.pasos li:last-child a {
  background: transparent !important;
  color: transparent;
  pointer-events: none;
  cursor: default;
}
/* line 3772, ../sass/_main.scss */
.pasos li.paso-activo a {
  background: #009bae;
}
/* line 3774, ../sass/_main.scss */
.pasos li.paso-activo a:after {
  border-left-color: #009bae !important;
}
/* line 3778, ../sass/_main.scss */
.pasos li.paso-activo-afiliado a {
  background: #002c3e;
}
/* line 3780, ../sass/_main.scss */
.pasos li.paso-activo-afiliado a:after {
  border-left-color: #002c3e !important;
}

/* line 3786, ../sass/_main.scss */
.pasos-afiliado {
  list-style: none;
  overflow: hidden;
  margin-top: 36px;
}
/* line 3790, ../sass/_main.scss */
.pasos-afiliado li {
  float: left;
}
/* line 3792, ../sass/_main.scss */
.pasos-afiliado li a {
  color: #ffffff;
  text-decoration: none;
  padding: 4px 0 4px 50px;
  background: #adadad;
  position: relative;
  display: block;
  float: left;
}
/* line 3800, ../sass/_main.scss */
.pasos-afiliado li a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #adadad;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
/* line 3814, ../sass/_main.scss */
.pasos-afiliado li a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ffffff;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}
/* line 3829, ../sass/_main.scss */
.pasos-afiliado li a:hover {
  background: #002c3e;
}
/* line 3831, ../sass/_main.scss */
.pasos-afiliado li a:hover:after {
  border-left-color: #002c3e !important;
}
/* line 3836, ../sass/_main.scss */
.pasos-afiliado li:last-child a {
  background: transparent !important;
  color: transparent;
  pointer-events: none;
  cursor: default;
}
/* line 3842, ../sass/_main.scss */
.pasos-afiliado li.paso-activo-afiliado a {
  background: #002c3e;
}
/* line 3844, ../sass/_main.scss */
.pasos-afiliado li.paso-activo-afiliado a:hover {
  background: #002c3e;
}
/* line 3846, ../sass/_main.scss */
.pasos-afiliado li.paso-activo-afiliado a:hover:after {
  border-left-color: #002c3e !important;
}
/* line 3850, ../sass/_main.scss */
.pasos-afiliado li.paso-activo-afiliado a:after {
  border-left-color: #002c3e !important;
}

/* line 3856, ../sass/_main.scss */
#tituloTablaCuotasAdmin {
  float: left;
}

/* line 3859, ../sass/_main.scss */
#editarCuotasPrestatario {
  float: right;
}

/* line 3862, ../sass/_main.scss */
.nopadding {
  padding-left: 0;
  padding-right: 0;
}

/* line 3866, ../sass/_main.scss */
.negrita {
  font-weight: bold;
}

/* line 3869, ../sass/_main.scss */
.nowrap {
  white-space: nowrap;
}

/* line 3872, ../sass/_main.scss */
.wrap {
  white-space: normal;
}

/* line 3875, ../sass/_main.scss */
.centrar {
  text-align: center;
}

/* line 3879, ../sass/_main.scss */
.centradoenbox {
  position: absolute;
  top: 53%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

/* line 3887, ../sass/_main.scss */
.tachado {
  text-decoration: line-through;
}

/* line 3890, ../sass/_main.scss */
.capitalizar {
  text-transform: capitalize;
}

/* line 3893, ../sass/_main.scss */
.estados-cuotas {
  padding: 2px 7px;
  font-size: 12px;
}

/* line 3897, ../sass/_main.scss */
.margenup_50 {
  padding: 50px 0 0;
}

/* line 3900, ../sass/_main.scss */
.margendown_50 {
  padding: 0 0 50px 0;
}

/* line 3903, ../sass/_main.scss */
.margenupdown_50 {
  padding: 50px 0;
}

/* line 3906, ../sass/_main.scss */
.margenall_50 {
  padding: 50px;
}

/* line 3909, ../sass/_main.scss */
.margenupdown_20 {
  padding: 20px 0;
}

/* line 3912, ../sass/_main.scss */
.margenup_20 {
  padding: 20px 0 0;
}

/* line 3915, ../sass/_main.scss */
.margendown_20 {
  padding: 0 0 20px 0;
}

/* line 3918, ../sass/_main.scss */
.margenall_20 {
  padding: 20px;
}

/* line 3921, ../sass/_main.scss */
.texto-desactivado {
  color: #999;
}

/* line 3924, ../sass/_main.scss */
.canvasImage {
  margin-top: 30px;
}

@media (max-width: 1200px) {
  /* line 3928, ../sass/_main.scss */
  .kpi_panel {
    font-size: 30px;
    padding: 33px 0;
  }

  /* line 3932, ../sass/_main.scss */
  .kpi_panel_small {
    padding: 10px 0;
  }

  /* line 3935, ../sass/_main.scss */
  .kpi_monedero {
    padding: 12px;
  }

  /* line 3938, ../sass/_main.scss */
  .carousel-left-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -17px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 3946, ../sass/_main.scss */
  .carousel-right-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -17px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 3954, ../sass/_main.scss */
  .carousel-left-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -53px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 3962, ../sass/_main.scss */
  .carousel-right-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -55px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 3970, ../sass/_main.scss */
  .carousel-left-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -17px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 3978, ../sass/_main.scss */
  .carousel-right-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -17px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 3986, ../sass/_main.scss */
  .pasos {
    margin-top: 33px;
  }
  /* line 3988, ../sass/_main.scss */
  .pasos li a {
    padding: 4px 0 4px 40px;
  }

  /* line 3992, ../sass/_main.scss */
  .col-top-box {
    height: auto;
  }

  /* line 3996, ../sass/_main.scss */
  .fondo-md-submenu.inversor {
    background-position-y: 200px;
  }
  /* line 3999, ../sass/_main.scss */
  .fondo-md-submenu.prestatario {
    background-position-y: 200px;
  }
  /* line 4002, ../sass/_main.scss */
  .fondo-md-submenu.afiliado {
    background-position-y: 200px;
  }

  /* line 4006, ../sass/_main.scss */
  canvas.graph {
    height: 242px;
  }

  /* line 4009, ../sass/_main.scss */
  .nav.navbar-nav.navbar-right.nav-subseccion {
    width: 100%;
    margin-right: -13px;
    margin-bottom: 10px;
  }
  /* line 4013, ../sass/_main.scss */
  .nav.navbar-nav.navbar-right.nav-subseccion > li.submenu-li {
    margin: 2px 3px;
    float: none;
  }
  /* line 4016, ../sass/_main.scss */
  .nav.navbar-nav.navbar-right.nav-subseccion > li.submenu-li a {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
@media (max-width: 992px) {
  /* line 4024, ../sass/_main.scss */
  .rectangulo-webcam-foto {
    display: none;
  }

  /* line 4028, ../sass/_main.scss */
  .foto-dni .make-picture-dni-frontal #canvas-frontal {
    width: 100%;
  }
  /* line 4031, ../sass/_main.scss */
  .foto-dni .make-picture-dni-trasera #canvas-trasera {
    width: 100%;
  }
}
@media (max-width: 979px) {
  /* line 4038, ../sass/_main.scss */
  .foto-dni .mobile-check-ok {
    display: none;
  }
  /* line 4041, ../sass/_main.scss */
  .foto-dni .btn-inversor {
    min-height: 62px;
  }

  /* line 4045, ../sass/_main.scss */
  .kpi_panel {
    font-size: 43px;
    padding: 40px 0;
  }

  /* line 4049, ../sass/_main.scss */
  .kpi_panel_small {
    padding: 20px 0;
  }

  /* line 4052, ../sass/_main.scss */
  .kpi_monedero {
    padding: 12px;
  }

  /* line 4055, ../sass/_main.scss */
  .carousel-left-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -31px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4063, ../sass/_main.scss */
  .carousel-right-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -36px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4071, ../sass/_main.scss */
  .carousel-left-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -90px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4079, ../sass/_main.scss */
  .carousel-right-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -90px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4087, ../sass/_main.scss */
  .carousel-left-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -31px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4095, ../sass/_main.scss */
  .carousel-right-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -36px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4103, ../sass/_main.scss */
  .pasos {
    margin-top: 32px;
  }
  /* line 4105, ../sass/_main.scss */
  .pasos li a {
    padding: 10px 0 10px 29px;
    font-size: 10px;
  }
  /* line 4108, ../sass/_main.scss */
  .pasos li a:after {
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 10px solid #adadad;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }
  /* line 4118, ../sass/_main.scss */
  .pasos li a:before {
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 10px solid #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }

  /* line 4131, ../sass/_main.scss */
  .bloque-testimonios {
    height: 175px;
  }

  /* line 4134, ../sass/_main.scss */
  canvas.graph {
    height: 180px;
  }
}
@media (max-width: 767px) {
  /* line 4140, ../sass/_main.scss */
  .foto-dni .mobile-check-ok {
    display: block;
  }
  /* line 4143, ../sass/_main.scss */
  .foto-dni .btn-prestatario .btn-verificacion-sub .camera-circle {
    font-size: 6em;
    width: 160px;
    height: 160px;
  }
  /* line 4148, ../sass/_main.scss */
  .foto-dni .btn-inversor .btn-verificacion-sub .camera-circle {
    font-size: 6em;
    width: 160px;
    height: 160px;
  }
  /* line 4153, ../sass/_main.scss */
  .foto-dni > .col-lg-6 {
    margin-bottom: 30px;
  }

  /* line 4157, ../sass/_main.scss */
  .navbar-fixed-top {
    position: absolute;
  }

  /* line 4160, ../sass/_main.scss */
  .navbar-nav {
    margin: inherit;
  }

  /* line 4163, ../sass/_main.scss */
  .carousel-left-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -85px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4171, ../sass/_main.scss */
  .carousel-right-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -85px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4179, ../sass/_main.scss */
  .carousel-left-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -85px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4187, ../sass/_main.scss */
  .carousel-right-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -85px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4195, ../sass/_main.scss */
  .carousel-left-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -85px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4203, ../sass/_main.scss */
  .carousel-right-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -85px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4211, ../sass/_main.scss */
  .col-top-box {
    padding: inherit;
    padding-top: 10px;
  }

  /* line 4215, ../sass/_main.scss */
  .col-std-landing {
    padding-top: 0;
  }

  /* line 4218, ../sass/_main.scss */
  .col-box-landing {
    background-size: cover;
    min-height: 455px;
  }

  /* line 4222, ../sass/_main.scss */
  .container-landing {
    padding: 0;
  }

  /* line 4225, ../sass/_main.scss */
  .panel-gris-landing {
    margin: 0;
    margin-bottom: 15px;
  }

  /* line 4229, ../sass/_main.scss */
  .bloque-testimonios {
    height: 136px;
  }

  /* line 4232, ../sass/_main.scss */
  canvas.graph {
    height: auto !important;
  }

  /* line 4235, ../sass/_main.scss */
  .nav.navbar-nav.navbar-right.nav-subseccion {
    width: auto;
    margin: 10px -3px;
  }

  /* line 4239, ../sass/_main.scss */
  .navbar-default.navbar-main .navbar-nav .open .dropdown-menu > li > a {
    color: #ededed;
  }
  /* line 4241, ../sass/_main.scss */
  .navbar-default.navbar-main .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #ffffff;
    background-color: transparent;
  }
  /* line 4245, ../sass/_main.scss */
  .navbar-default.navbar-main .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #ffffff;
    background-color: transparent;
  }
}
@media (max-width: 480px) {
  /* line 4253, ../sass/_main.scss */
  .foto-dni .btn-prestatario p {
    max-width: 90%;
  }
  /* line 4256, ../sass/_main.scss */
  .foto-dni .btn-inversor p {
    max-width: 90%;
  }

  /* line 4260, ../sass/_main.scss */
  .title-hero {
    margin-top: 235px;
  }
  /* line 4262, ../sass/_main.scss */
  .title-hero.inversor {
    margin-left: 0;
  }

  /* line 4266, ../sass/_main.scss */
  .borde-dash {
    width: 25%;
  }

  /* line 4269, ../sass/_main.scss */
  .borde-dash-verde {
    width: 25%;
  }

  /* line 4272, ../sass/_main.scss */
  .col-box {
    height: 223px;
  }

  /* line 4275, ../sass/_main.scss */
  .col-box-interno {
    height: 0;
    border: 0;
  }

  /* line 4279, ../sass/_main.scss */
  .col-box-landing {
    background-size: cover;
    height: auto;
  }

  /* line 4283, ../sass/_main.scss */
  .navbar-default.navbar-main .navbar-text {
    float: none;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  /* line 4288, ../sass/_main.scss */
  .navbar-btn {
    margin-left: 14px;
  }

  /* line 4291, ../sass/_main.scss */
  .navbar-fixed-top {
    position: absolute;
  }

  /* line 4294, ../sass/_main.scss */
  .title-big {
    font-size: 25px;
    line-height: 33px;
  }
  /* line 4297, ../sass/_main.scss */
  .title-big.prestatario {
    padding: 15px;
    font-size: 22px;
  }
  /* line 4301, ../sass/_main.scss */
  .title-big.inversor {
    padding: 15px;
    font-size: 22px;
  }
  /* line 4305, ../sass/_main.scss */
  .title-big.single-title-box {
    padding: 20px 0 0;
    font-size: 25px;
    line-height: 33px;
  }
  /* line 4310, ../sass/_main.scss */
  .title-big.doble-title-box {
    padding: 20px 0 0;
    font-size: 25px;
    line-height: 33px;
  }
  /* line 4315, ../sass/_main.scss */
  .title-big.triple-title-box {
    padding: 20px 0 0;
    font-size: 25px;
    line-height: 33px;
  }
  /* line 4320, ../sass/_main.scss */
  .title-big.quadra-title-box {
    padding: 20px 0;
    font-size: 25px;
    line-height: 33px;
  }

  /* line 4326, ../sass/_main.scss */
  .logo-index {
    width: 118px;
  }

  /* line 4329, ../sass/_main.scss */
  .row-logo-index {
    margin-top: 0;
  }

  /* line 4332, ../sass/_main.scss */
  .carousel-left-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -23px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4340, ../sass/_main.scss */
  .carousel-right-prestamos {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -23px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4348, ../sass/_main.scss */
  .carousel-left-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -23px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4356, ../sass/_main.scss */
  .carousel-right-estadisticas {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -23px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4364, ../sass/_main.scss */
  .carousel-left-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-left: -23px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4372, ../sass/_main.scss */
  .carousel-right-inversores {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 10px 3px 7px;
    margin-right: -23px;
    margin-top: 48px;
    text-shadow: none;
  }

  /* line 4380, ../sass/_main.scss */
  .texto-principal-index {
    margin-top: 7px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  /* line 4387, ../sass/_main.scss */
  .title-index.inversor {
    margin-top: 200px;
    margin-left: 0;
  }
  /* line 4391, ../sass/_main.scss */
  .title-index.prestatario {
    margin-top: 200px;
    margin-left: 0;
  }

  /* line 4396, ../sass/_main.scss */
  .form-login-navbar {
    padding: 10px 15px;
  }
  /* line 4398, ../sass/_main.scss */
  .form-login-navbar input {
    position: relative;
    display: block;
    width: 100%;
  }
  /* line 4403, ../sass/_main.scss */
  .form-login-navbar button {
    position: relative;
    display: block;
    width: 100%;
  }

  /* line 4409, ../sass/_main.scss */
  .btn-mainmenu {
    margin-left: 0;
    margin-top: 13px;
    padding: 6px 24px;
  }

  /* line 4414, ../sass/_main.scss */
  .fondomain {
    background-position-y: 30px;
  }

  /* line 4417, ../sass/_main.scss */
  .fondo1 {
    background-position-y: 88px;
  }

  /* line 4421, ../sass/_main.scss */
  .fondo-md-submenu.inversor {
    background-position-y: 380px;
  }
  /* line 4424, ../sass/_main.scss */
  .fondo-md-submenu.prestatario {
    background-position-y: 305px;
  }
  /* line 4427, ../sass/_main.scss */
  .fondo-md-submenu.afiliado {
    background-position-y: 305px;
  }
  /* line 4430, ../sass/_main.scss */
  .fondo-md-submenu.generico {
    background-position-y: 225px;
  }

  /* line 4434, ../sass/_main.scss */
  .bottom-title {
    font-size: 17px;
  }

  /* line 4437, ../sass/_main.scss */
  .cp-title {
    font-size: 17px;
  }

  /* line 4440, ../sass/_main.scss */
  .bottom-title-private {
    font-size: 20px;
  }

  /* line 4443, ../sass/_main.scss */
  .button-movil {
    display: block;
  }

  /* line 4446, ../sass/_main.scss */
  .btn-verificacion-primario {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 15px;
  }
  /* line 4450, ../sass/_main.scss */
  .btn-verificacion-primario:hover {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 15px;
  }
  /* line 4455, ../sass/_main.scss */
  .btn-verificacion-primario:focus {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 15px;
  }

  /* line 4461, ../sass/_main.scss */
  .margenupdown_50 {
    padding: 10px 0;
  }

  /* line 4464, ../sass/_main.scss */
  .margenup_50 {
    padding: 10px 0;
  }

  /* line 4467, ../sass/_main.scss */
  .margendown_50 {
    padding: 10px 0;
  }

  /* line 4470, ../sass/_main.scss */
  .nav.navbar-nav.navbar-right.nav-subseccion {
    width: auto;
    margin: 0 -3px 8px -2px;
  }

  /* line 4475, ../sass/_main.scss */
  .nav-tabs.nav-justified.nav-pestanas > .active > a {
    border: 1px solid #000000;
    border-radius: 0;
    background-color: #dddddd;
  }
  /* line 4480, ../sass/_main.scss */
  .nav-tabs.nav-justified.nav-pestanas > .active > a:hover {
    border: 1px solid #000000;
    border-radius: 0;
    background-color: #dddddd;
  }
  /* line 4487, ../sass/_main.scss */
  .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #000000;
    border-radius: 0;
    background-color: #dddddd;
  }

  /* line 4494, ../sass/_main.scss */
  .submenu-interior > .submenu-li {
    margin: 7px 5px 0;
  }

  /* line 4497, ../sass/_main.scss */
  .btn-simulador.prestatario {
    font-size: 10px;
    padding: 14px 6px;
  }
}
@media (max-width: 378px) {
  /* line 4504, ../sass/_main.scss */
  .foto-dni .btn-prestatario p {
    max-width: 87%;
    font-size: 13px;
  }
  /* line 4508, ../sass/_main.scss */
  .foto-dni .btn-inversor p {
    max-width: 87%;
    font-size: 13px;
  }
}
@media (max-width: 325px) {
  /* line 4515, ../sass/_main.scss */
  .btn-inversor {
    padding-left: 10px;
  }

  /* line 4519, ../sass/_main.scss */
  .foto-dni .mobile-check-ok {
    margin-top: 5px;
    font-size: 20px;
  }
  /* line 4523, ../sass/_main.scss */
  .foto-dni .btn-prestatario p {
    font-size: 11px;
  }
  /* line 4526, ../sass/_main.scss */
  .foto-dni .btn-inversor p {
    font-size: 11px;
  }
}
/* line 4531, ../sass/_main.scss */
.title-index.prestatario {
  margin-left: -70px;
}

/* line 4534, ../sass/_main.scss */
.label > .editable-click {
  color: #ffffff;
}

/* line 4537, ../sass/_main.scss */
nav.navbar {
  margin-right: 0 !important;
}

/* line 4540, ../sass/_main.scss */
.navbar-default.navbar-main {
  border-radius: 0;
}
/* line 4545, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > .open > a {
  color: #ededed;
  background-color: transparent;
}
/* line 4548, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > .open > a:hover {
  color: #ededed;
  background-color: transparent;
}
/* line 4552, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > .open > a:focus {
  color: #ededed;
  background-color: transparent;
}
/* line 4557, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > .active > a {
  color: #cbcbcb;
  background-color: transparent;
}
/* line 4560, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > .active > a:hover {
  color: #cbcbcb;
  background-color: transparent;
}
/* line 4564, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > .active > a:focus {
  color: #cbcbcb;
  background-color: transparent;
}
/* line 4570, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > li > a {
  color: #ededed;
  background-color: transparent;
}
/* line 4573, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > li > a:hover {
  color: #ffffff;
}
/* line 4576, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > li > a:active {
  color: #ffffff;
}
/* line 4579, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-nav > li > a .active {
  color: #ffffff;
}
/* line 4584, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-link {
  display: inline-block;
  color: #ededed;
  background-color: transparent;
  font-family: "Noto Sans", "sans-serif";
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
}
/* line 4592, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-link:hover {
  color: #ffffff;
  font-family: "Noto Sans", "sans-serif";
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
}
/* line 4599, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-link:active {
  color: #ffffff;
}
/* line 4602, ../sass/_main.scss */
.navbar-default.navbar-main .navbar-link .active {
  color: #ffffff;
}
/* line 4606, ../sass/_main.scss */
.navbar-default.navbar-main .new-margin-right {
  margin-right: 30px;
}

/* line 4611, ../sass/_main.scss */
.nav.navbar-nav.navbar-right.nav-subseccion {
  margin-right: -5px;
}
/* line 4613, ../sass/_main.scss */
.nav.navbar-nav.navbar-right.nav-subseccion.nav-subseccion-sinlinea {
  margin-right: -15px;
}

/* line 4621, ../sass/_main.scss */
.nav-tabs.nav-justified.nav-pestanas > .active > a {
  border: 1px solid #000000;
  border-radius: 0;
  border-bottom-color: #ffffff;
}
/* line 4626, ../sass/_main.scss */
.nav-tabs.nav-justified.nav-pestanas > .active > a:hover {
  border: 1px solid #000000;
  border-radius: 0;
  border-bottom-color: #ffffff;
}
/* line 4633, ../sass/_main.scss */
.nav-tabs.nav-justified.nav-pestanas > li > a {
  border-bottom: 1px solid #000000;
  border-radius: 0;
  font-family: "Lato", "sans-serif";
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: #000000;
}
/* line 4644, ../sass/_main.scss */
.nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #000000;
  border-radius: 0;
  border-bottom-color: #ffffff;
}

/* line 4652, ../sass/_main.scss */
.panel-group .panel {
  border-radius: 0;
}
/* line 4655, ../sass/_main.scss */
.panel-group .panel + .panel {
  margin-top: -2px;
}

/* line 4659, ../sass/_main.scss */
h4.panel-title {
  font-family: "Lato", "sans-serif";
  font-weight: 400;
}

/* line 4663, ../sass/_main.scss */
h2.panel-title {
  font-family: "Lato", "sans-serif";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
}

/* line 4669, ../sass/_main.scss */
.panel-heading.panel-heading-tabla {
  border: 1px solid #000000;
  margin-bottom: 35px;
  border-radius: 0;
}

/* line 4675, ../sass/_main.scss */
.btn-verificacion-sub ul {
  text-align: left;
  padding-top: 15px;
  font-size: 15px;
}

/* line 4680, ../sass/_main.scss */
label.error {
  padding: 0 4px;
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  color: red;
}

/* line 4689, ../sass/_main.scss */
.fechas-select select {
  border: none;
  padding: 6px 48% 5px;
  outline: none;
}

/* line 4694, ../sass/_main.scss */
.fechas-dual-select select {
  border: none;
  padding: 2px 20px 0 12px;
  outline: none;
}

/* line 4700, ../sass/_main.scss */
.dni-inversor .panel.panel-default .panel-body .foto-dni.dni .col-lg-6 {
  float: none;
  width: 100%;
}
/* line 4703, ../sass/_main.scss */
.dni-inversor .panel.panel-default .panel-body .foto-dni.dni .col-lg-6 canvas {
  width: 100%;
}
/* line 4707, ../sass/_main.scss */
.dni-inversor .panel.panel-default .panel-body .foto-dni.dni > .col-lg-6 {
  margin-bottom: 30px;
}

/* line 4711, ../sass/_main.scss */
.slider .bar {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 100%;
  margin: 10px 0;
  height: 16px;
  display: inline-block;
  position: relative;
  background-color: #f7f7f7;
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color_wild_sand_approx',endcolorstr='$color_alabaster_approx',gradienttype=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
}
/* line 4729, ../sass/_main.scss */
.slider .bar .icon-progress-holder {
  position: absolute;
  left: 50%;
  top: -7px;
  margin-left: -15px;
  z-index: 100;
  background: none;
  background-image: url("/img/botonslider.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 1px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 30px;
  height: 29px;
  outline: none;
  border: none;
}
/* line 4748, ../sass/_main.scss */
.slider .bar .ui-slider-handle {
  position: absolute;
  left: 50%;
  top: -7px;
  margin-left: -15px;
  z-index: 100;
  background: none;
  background-image: url("/img/botonslider.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 1px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 30px;
  height: 29px;
  outline: none;
  border: none;
}
/* line 4766, ../sass/_main.scss */
.slider .bar .ui-slider-handle .ui-state-active {
  position: absolute;
  left: 50%;
  top: -7px;
  margin-left: -15px;
  z-index: 100;
  background: none;
  background-image: url("/img/botonslider.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 1px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 30px;
  height: 29px;
  outline: none;
  border: none;
}
/* line 4785, ../sass/_main.scss */
.slider .bar .ui-slider-handle .ui-state-focus {
  position: absolute;
  left: 50%;
  top: -7px;
  margin-left: -15px;
  z-index: 100;
  background: none;
  background-image: url("/img/botonslider.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 1px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 30px;
  height: 29px;
  outline: none;
  border: none;
}

/* line 4806, ../sass/_main.scss */
.bar .ui-slider-range {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #149bdf;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  -webkit-background-size: 16px 16px;
  -moz-background-size: 16px 16px;
  -o-background-size: 16px 16px;
  background-size: 16px 16px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
}

/* line 4826, ../sass/_main.scss */
.input-slider-lg > .input-lg {
  font-size: 24px;
}
/* line 4829, ../sass/_main.scss */
.input-slider-lg > .input-lg.prestatario {
  padding: 10px 11px;
}

/* line 4833, ../sass/_main.scss */
hr.soften {
  height: 1px;
  margin: 5px 0;
  background-image: -o-linear-gradient(left, transparent, rgba(0, 0, 0, 0.1), transparent);
  border: 0;
}

/* line 4839, ../sass/_main.scss */
.media-body.preguntas {
  padding-left: 15px;
}

/* line 4842, ../sass/_main.scss */
.tab-content.tab-clean {
  background-color: #ffffff;
  min-height: 300px;
  border: none;
  padding: 0;
}

/* line 4848, ../sass/_main.scss */
.info-tooltip-negro > a {
  color: #666666;
  text-decoration: none;
}
/* line 4851, ../sass/_main.scss */
.info-tooltip-negro > a:hover {
  text-decoration: none;
  color: #000000;
}

/*.tooltip > {
  .tooltip-inner {
    background-color: $white;
    color: $black;
    border: 1px solid $color_mine_shaft_approx;
    text-transform: none;
    opacity: 1;
    min-width: 200px;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    font-weight: normal;
  }
  .tooltip-arrow {
    background-color: $white;
    border: none;
  }
}*/
/* line 4875, ../sass/_main.scss */
.list-group .popover h3.popover-title {
  color: #434343;
}

@media (max-width: 375px) {
  /* line 4883, ../sass/_main.scss */
  .plupload_filelist_header.ui-widget-header.table.table-hover tbody tr td, .plupload_filelist_footer.ui-widget-header.table.table-hover tbody tr td {
    display: none;
  }
  /* line 4886, ../sass/_main.scss */
  .plupload_filelist_header.ui-widget-header.table.table-hover tbody tr td:first-child, .plupload_filelist_footer.ui-widget-header.table.table-hover tbody tr td:first-child {
    display: table-cell;
  }
}

@media (max-width: 480px) {
  /* line 4894, ../sass/_main.scss */
  .btn-info {
    margin-bottom: 10px;
  }
}

/* line 4901, ../sass/_main.scss */
#modal_envio_docs .modal-content {
  padding: 0;
}
/* line 4905, ../sass/_main.scss */
#modal_envio_docs .modal-header {
  padding: 27px;
  background: #009bae;
  color: #fff;
}
/* line 4910, ../sass/_main.scss */
#modal_envio_docs .modal-header h3 {
  color: #fff;
}
/* line 4914, ../sass/_main.scss */
#modal_envio_docs .modal-body {
  padding: 27px;
}
/* line 4916, ../sass/_main.scss */
#modal_envio_docs .modal-body p {
  margin: 0 0 1em;
  line-height: 1.4;
  font-size: 16px;
  font-family: Lato;
  font-weight: 300;
}
/* line 4924, ../sass/_main.scss */
#modal_envio_docs .modal-body .spinner {
  margin: 35px auto 15px;
  text-align: center;
}
/* line 4927, ../sass/_main.scss */
#modal_envio_docs .modal-body .spinner img {
  width: 69%;
}

/* line 4936, ../sass/_main.scss */
#modal_envio.afiliado .modal-header {
  background: #002c3e;
}
/* line 4940, ../sass/_main.scss */
#modal_envio .modal-content {
  padding: 0;
}
/* line 4944, ../sass/_main.scss */
#modal_envio .modal-header {
  padding: 27px;
  background: #009bae;
  color: #fff;
}
/* line 4949, ../sass/_main.scss */
#modal_envio .modal-header h3 {
  color: #fff;
}
/* line 4953, ../sass/_main.scss */
#modal_envio .modal-body {
  padding: 27px;
}
/* line 4955, ../sass/_main.scss */
#modal_envio .modal-body p {
  margin: 0 0 1em;
  line-height: 1.4;
  font-size: 16px;
  font-family: Lato;
  font-weight: 300;
}
/* line 4963, ../sass/_main.scss */
#modal_envio .modal-body .spinner {
  margin: 35px auto 15px;
  text-align: center;
}
/* line 4966, ../sass/_main.scss */
#modal_envio .modal-body .spinner img {
  width: 69%;
}

/* line 4973, ../sass/_main.scss */
.spinner-long {
  margin: 35px auto 15px;
  text-align: center;
}
/* line 4976, ../sass/_main.scss */
.spinner-long img {
  width: 38%;
}

/* line 4982, ../sass/_main.scss */
.preferencias-editables-inversor .editable-container {
  height: 18px;
}
/* line 4986, ../sass/_main.scss */
.preferencias-editables-inversor .editable-container .editable-input input {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c9a75d;
  color: #555;
  outline: 0;
  font-style: italic;
  font-family: "Noto Sans", "sans-serif";
  padding: 0 0 5px 5px;
  height: 24px;
}
/* line 5001, ../sass/_main.scss */
.preferencias-editables-inversor .editable-container .editable-error-block {
  padding: 0 4px;
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  background-color: #ebcaa6;
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
  max-width: 100%;
  text-align: left;
  color: #434343;
  font-family: "Lato", "sans-serif";
  line-height: 1.2;
  height: 15px;
}

/* line 5024, ../sass/_main.scss */
table#lista-monederos tbody tr td:last-child {
  text-align: right;
}

/* line 5030, ../sass/_main.scss */
h3.popover-title {
  color: #1a1a1a;
}

/* line 5033, ../sass/_main.scss */
.button-table {
  display: inline-block;
  cursor: pointer;
}

/* line 5038, ../sass/_main.scss */
body div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  background: none;
}
/* line 5043, ../sass/_main.scss */
body div.dataTables_processing i {
  margin-left: -70px;
  margin-top: -35px;
}

/* line 5049, ../sass/_main.scss */
.btn-paginate, .btn-paginate:hover, .btn-paginate:focus, .btn-paginate:active {
  border: 2px solid #999 !important;
}

/* line 5054, ../sass/_main.scss */
h2.info-tooltip i.searchClick {
  color: #737373;
  cursor: pointer;
}

/* line 5060, ../sass/_main.scss */
.dataTables_filter {
  display: none;
}

/* line 5069, ../sass/_main.scss */
#lista-prestamos_wrapper #lista-prestamos tbody tr td {
  border-bottom: 1px solid #000000;
}

/* line 5077, ../sass/_main.scss */
.tipo {
  border: 2px solid black;
  border-radius: 30px;
  padding: 5px 13px;
}

/* line 5086, ../sass/_main.scss */
#documentacion-dni .btn-superblock .info p, #documentacion-iban .btn-superblock .info p {
  float: none;
  max-width: 100%;
}
/* line 5090, ../sass/_main.scss */
#documentacion-dni .btn-superblock .info p.right, #documentacion-iban .btn-superblock .info p.right {
  float: right;
  max-width: 70%;
}
/* line 4, ../sass/_mixins.scss */
#documentacion-dni .info, #documentacion-iban .info {
  *zoom: 1;
}
/* line 7, ../sass/_mixins.scss */
#documentacion-dni .info:before, #documentacion-dni .info:after, #documentacion-iban .info:before, #documentacion-iban .info:after {
  content: "";
  display: table;
}
/* line 12, ../sass/_mixins.scss */
#documentacion-dni .info:after, #documentacion-iban .info:after {
  clear: both;
}
/* line 5101, ../sass/_main.scss */
#documentacion-dni .info p, #documentacion-iban .info p {
  margin: 0;
  float: right;
  max-width: 70%;
  white-space: normal;
}
/* line 5107, ../sass/_main.scss */
#documentacion-dni .info .icons, #documentacion-iban .info .icons {
  float: left;
  margin-top: 8px;
  font-size: 17px;
}
/* line 5114, ../sass/_main.scss */
#documentacion-dni .options, #documentacion-iban .options {
  display: none;
  padding: 10px 0;
}
/* line 5118, ../sass/_main.scss */
#documentacion-dni .options p, #documentacion-iban .options p {
  margin: 0;
}
/* line 5122, ../sass/_main.scss */
#documentacion-dni .foto-frontal form, #documentacion-dni .foto-trasera form, #documentacion-dni .foto-pasaporte form, #documentacion-dni .foto-iban form, #documentacion-iban .foto-frontal form, #documentacion-iban .foto-trasera form, #documentacion-iban .foto-pasaporte form, #documentacion-iban .foto-iban form {
  display: none;
}

/* line 5126, ../sass/_main.scss */
#frontal, #trasera, #pasaporte, #iban {
  float: none;
  margin: 1em auto;
}
@media (max-width: 768px) {
  /* line 5126, ../sass/_main.scss */
  #frontal, #trasera, #pasaporte, #iban {
    margin-bottom: 10px;
  }
}
/* line 5133, ../sass/_main.scss */
#frontal.inactive, #trasera.inactive, #pasaporte.inactive, #iban.inactive {
  opacity: 0.3;
}

/* line 5138, ../sass/_main.scss */
.foto-frontal canvas, .foto-trasera canvas, .foto-pasaporte canvas, .foto-iban canvas {
  transform: rotateY(180deg);
}
/* line 5142, ../sass/_main.scss */
.foto-frontal .hacer-foto, .foto-trasera .hacer-foto, .foto-pasaporte .hacer-foto, .foto-iban .hacer-foto {
  display: block;
  height: 40px;
  margin-top: -5px;
}
/* line 5147, ../sass/_main.scss */
.foto-frontal .hacer-foto .pre_take_buttons, .foto-trasera .hacer-foto .pre_take_buttons, .foto-pasaporte .hacer-foto .pre_take_buttons, .foto-iban .hacer-foto .pre_take_buttons {
  text-align: center;
  padding: 10px;
  background: #009bae;
  margin: 0 auto;
  color: white;
  cursor: pointer;
}
/* line 5156, ../sass/_main.scss */
.foto-frontal .hacer-foto .post_take_buttons, .foto-trasera .hacer-foto .post_take_buttons, .foto-pasaporte .hacer-foto .post_take_buttons, .foto-iban .hacer-foto .post_take_buttons {
  display: none;
}
/* line 5159, ../sass/_main.scss */
.foto-frontal .hacer-foto .post_take_buttons .cancel-preview, .foto-trasera .hacer-foto .post_take_buttons .cancel-preview, .foto-pasaporte .hacer-foto .post_take_buttons .cancel-preview, .foto-iban .hacer-foto .post_take_buttons .cancel-preview {
  float: left;
  background: #999;
}
/* line 5163, ../sass/_main.scss */
.foto-frontal .hacer-foto .post_take_buttons .save-preview, .foto-trasera .hacer-foto .post_take_buttons .save-preview, .foto-pasaporte .hacer-foto .post_take_buttons .save-preview, .foto-iban .hacer-foto .post_take_buttons .save-preview {
  float: right;
  background: #009bae;
}
/* line 5168, ../sass/_main.scss */
.foto-frontal .hacer-foto .post_take_buttons .save-preview, .foto-frontal .hacer-foto .post_take_buttons .cancel-preview, .foto-trasera .hacer-foto .post_take_buttons .save-preview, .foto-trasera .hacer-foto .post_take_buttons .cancel-preview, .foto-pasaporte .hacer-foto .post_take_buttons .save-preview, .foto-pasaporte .hacer-foto .post_take_buttons .cancel-preview, .foto-iban .hacer-foto .post_take_buttons .save-preview, .foto-iban .hacer-foto .post_take_buttons .cancel-preview {
  width: 50%;
  text-align: center;
  padding: 10px;
  margin: 0px auto;
  color: white;
  cursor: pointer;
}

/* line 5181, ../sass/_main.scss */
.instrucciones .problemsDni {
  cursor: pointer;
}

/* line 5187, ../sass/_main.scss */
#acreditacion label {
  font-family: "Lato";
  font-size: 15px;
  text-align: left;
  font-weight: 400;
}
/* line 5193, ../sass/_main.scss */
#acreditacion label input {
  margin-right: 9px;
}
/* line 5198, ../sass/_main.scss */
#acreditacion .documentos {
  margin-top: 20px;
}
/* line 5200, ../sass/_main.scss */
#acreditacion .documentos ol {
  margin-bottom: 20px;
}
/* line 5206, ../sass/_main.scss */
#acreditacion .enviarAcreditacion ul {
  text-align: left;
}
/* line 5208, ../sass/_main.scss */
#acreditacion .enviarAcreditacion ul li {
  font-family: "Lato";
  font-size: 15px;
  text-align: left;
  font-weight: 400;
}

/* line 5219, ../sass/_main.scss */
.fondo-afiliado .ui-widget-header {
  background: #002c3e;
  border-color: #002c3e;
}

/* line 5225, ../sass/_main.scss */
.fondo-afiliado-claro {
  background: #333a3c;
}

/* line 5233, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container, #fileuploadResidencia .plupload_container, #fileuploadEstatutos .plupload_container, #fileuploadDniAf .plupload_container,
#fileuploadContratoAf .plupload_container, #fileuploadLogoAf .plupload_container, #fileuploadReciboDomicilio .plupload_container, #fileuploadDniFrontal .plupload_container, #fileuploadIBANAf .plupload_container,
#fileuploadDniTrasera .plupload_container, #fileuploadPasaporte .plupload_container, #fileuploadCertificado .plupload_container, #fileuploadNieFrontal .plupload_container, #fileuploadNieTrasera .plupload_container,
#fileuploadCuentasAnualesAf .plupload_container {
  height: 105px;
  min-height: 105px;
  border: 0;
  border-bottom: 1px solid #000;
}
/* line 5239, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_filelist_header, #fileuploadResidencia .plupload_container .plupload_filelist_header, #fileuploadEstatutos .plupload_container .plupload_filelist_header, #fileuploadDniAf .plupload_container .plupload_filelist_header,
#fileuploadContratoAf .plupload_container .plupload_filelist_header, #fileuploadLogoAf .plupload_container .plupload_filelist_header, #fileuploadReciboDomicilio .plupload_container .plupload_filelist_header, #fileuploadDniFrontal .plupload_container .plupload_filelist_header, #fileuploadIBANAf .plupload_container .plupload_filelist_header,
#fileuploadDniTrasera .plupload_container .plupload_filelist_header, #fileuploadPasaporte .plupload_container .plupload_filelist_header, #fileuploadCertificado .plupload_container .plupload_filelist_header, #fileuploadNieFrontal .plupload_container .plupload_filelist_header, #fileuploadNieTrasera .plupload_container .plupload_filelist_header,
#fileuploadCuentasAnualesAf .plupload_container .plupload_filelist_header {
  color: #000;
  background: #fff;
  border-bottom: 0;
}
/* line 5244, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_content.plupload_dropbox, #fileuploadResidencia .plupload_container .plupload_content.plupload_dropbox, #fileuploadEstatutos .plupload_container .plupload_content.plupload_dropbox, #fileuploadDniAf .plupload_container .plupload_content.plupload_dropbox,
#fileuploadContratoAf .plupload_container .plupload_content.plupload_dropbox, #fileuploadLogoAf .plupload_container .plupload_content.plupload_dropbox, #fileuploadReciboDomicilio .plupload_container .plupload_content.plupload_dropbox, #fileuploadDniFrontal .plupload_container .plupload_content.plupload_dropbox, #fileuploadIBANAf .plupload_container .plupload_content.plupload_dropbox,
#fileuploadDniTrasera .plupload_container .plupload_content.plupload_dropbox, #fileuploadPasaporte .plupload_container .plupload_content.plupload_dropbox, #fileuploadCertificado .plupload_container .plupload_content.plupload_dropbox, #fileuploadNieFrontal .plupload_container .plupload_content.plupload_dropbox, #fileuploadNieTrasera .plupload_container .plupload_content.plupload_dropbox,
#fileuploadCuentasAnualesAf .plupload_container .plupload_content.plupload_dropbox {
  overflow: initial;
}
/* line 5247, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_droptext, #fileuploadResidencia .plupload_container .plupload_droptext, #fileuploadEstatutos .plupload_container .plupload_droptext, #fileuploadDniAf .plupload_container .plupload_droptext,
#fileuploadContratoAf .plupload_container .plupload_droptext, #fileuploadLogoAf .plupload_container .plupload_droptext, #fileuploadReciboDomicilio .plupload_container .plupload_droptext, #fileuploadDniFrontal .plupload_container .plupload_droptext, #fileuploadIBANAf .plupload_container .plupload_droptext,
#fileuploadDniTrasera .plupload_container .plupload_droptext, #fileuploadPasaporte .plupload_container .plupload_droptext, #fileuploadCertificado .plupload_container .plupload_droptext, #fileuploadNieFrontal .plupload_container .plupload_droptext, #fileuploadNieTrasera .plupload_container .plupload_droptext,
#fileuploadCuentasAnualesAf .plupload_container .plupload_droptext {
  display: none;
}
/* line 5250, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_filelist_footer, #fileuploadResidencia .plupload_container .plupload_filelist_footer, #fileuploadEstatutos .plupload_container .plupload_filelist_footer, #fileuploadDniAf .plupload_container .plupload_filelist_footer,
#fileuploadContratoAf .plupload_container .plupload_filelist_footer, #fileuploadLogoAf .plupload_container .plupload_filelist_footer, #fileuploadReciboDomicilio .plupload_container .plupload_filelist_footer, #fileuploadDniFrontal .plupload_container .plupload_filelist_footer, #fileuploadIBANAf .plupload_container .plupload_filelist_footer,
#fileuploadDniTrasera .plupload_container .plupload_filelist_footer, #fileuploadPasaporte .plupload_container .plupload_filelist_footer, #fileuploadCertificado .plupload_container .plupload_filelist_footer, #fileuploadNieFrontal .plupload_container .plupload_filelist_footer, #fileuploadNieTrasera .plupload_container .plupload_filelist_footer,
#fileuploadCuentasAnualesAf .plupload_container .plupload_filelist_footer {
  color: #000;
  background: #fff;
  border: 0;
}

/* line 5258, ../sass/_main.scss */
.parpadeoAdmin {
  animation-name: parpadeo;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}
@-webkit-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}
@keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}
/* line 5296, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container, #fileuploadResidencia .plupload_container, #fileuploadEstatutos .plupload_container, #fileuploadDniAf .plupload_container,
#fileuploadContratoAf .plupload_container, #fileuploadLogoAf .plupload_container, #fileuploadReciboDomicilio .plupload_container, #fileuploadDniFrontal .plupload_container,
#fileuploadDniTrasera .plupload_container, #fileuploadPasaporte .plupload_container, #fileuploadCertificado .plupload_container, #fileuploadIban .plupload_container,
#fileuploadNomina .plupload_container, #fileuploadContratoInem .plupload_container, #fileuploadIRPF .plupload_container, #fileuploadCarta .plupload_container, #fileuploadDesempleo .plupload_container,
#fileuploadCarneTrasera .plupload_container, #fileuploadCarneFrontal .plupload_container, #fileuploadIdCardTrasera .plupload_container, #fileuploadIdCardFrontal .plupload_container,
#fileuploadContratos .plupload_container, #fileuploadAutorizacion .plupload_container, #fileuploadCuentasAnualesAf .plupload_container {
  height: 105px;
  min-height: 105px;
  border: 0;
  border-bottom: 1px solid #000;
}
/* line 5302, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_filelist_header, #fileuploadResidencia .plupload_container .plupload_filelist_header, #fileuploadEstatutos .plupload_container .plupload_filelist_header, #fileuploadDniAf .plupload_container .plupload_filelist_header,
#fileuploadContratoAf .plupload_container .plupload_filelist_header, #fileuploadLogoAf .plupload_container .plupload_filelist_header, #fileuploadReciboDomicilio .plupload_container .plupload_filelist_header, #fileuploadDniFrontal .plupload_container .plupload_filelist_header,
#fileuploadDniTrasera .plupload_container .plupload_filelist_header, #fileuploadPasaporte .plupload_container .plupload_filelist_header, #fileuploadCertificado .plupload_container .plupload_filelist_header, #fileuploadIban .plupload_container .plupload_filelist_header,
#fileuploadNomina .plupload_container .plupload_filelist_header, #fileuploadContratoInem .plupload_container .plupload_filelist_header, #fileuploadIRPF .plupload_container .plupload_filelist_header, #fileuploadCarta .plupload_container .plupload_filelist_header, #fileuploadDesempleo .plupload_container .plupload_filelist_header,
#fileuploadCarneTrasera .plupload_container .plupload_filelist_header, #fileuploadCarneFrontal .plupload_container .plupload_filelist_header, #fileuploadIdCardTrasera .plupload_container .plupload_filelist_header, #fileuploadIdCardFrontal .plupload_container .plupload_filelist_header,
#fileuploadContratos .plupload_container .plupload_filelist_header, #fileuploadAutorizacion .plupload_container .plupload_filelist_header, #fileuploadCuentasAnualesAf .plupload_container .plupload_filelist_header {
  color: #000;
  background: #fff;
  border-bottom: 0;
}
/* line 5307, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_content.plupload_dropbox, #fileuploadResidencia .plupload_container .plupload_content.plupload_dropbox, #fileuploadEstatutos .plupload_container .plupload_content.plupload_dropbox, #fileuploadDniAf .plupload_container .plupload_content.plupload_dropbox,
#fileuploadContratoAf .plupload_container .plupload_content.plupload_dropbox, #fileuploadLogoAf .plupload_container .plupload_content.plupload_dropbox, #fileuploadReciboDomicilio .plupload_container .plupload_content.plupload_dropbox, #fileuploadDniFrontal .plupload_container .plupload_content.plupload_dropbox,
#fileuploadDniTrasera .plupload_container .plupload_content.plupload_dropbox, #fileuploadPasaporte .plupload_container .plupload_content.plupload_dropbox, #fileuploadCertificado .plupload_container .plupload_content.plupload_dropbox, #fileuploadIban .plupload_container .plupload_content.plupload_dropbox,
#fileuploadNomina .plupload_container .plupload_content.plupload_dropbox, #fileuploadContratoInem .plupload_container .plupload_content.plupload_dropbox, #fileuploadIRPF .plupload_container .plupload_content.plupload_dropbox, #fileuploadCarta .plupload_container .plupload_content.plupload_dropbox, #fileuploadDesempleo .plupload_container .plupload_content.plupload_dropbox,
#fileuploadCarneTrasera .plupload_container .plupload_content.plupload_dropbox, #fileuploadCarneFrontal .plupload_container .plupload_content.plupload_dropbox, #fileuploadIdCardTrasera .plupload_container .plupload_content.plupload_dropbox, #fileuploadIdCardFrontal .plupload_container .plupload_content.plupload_dropbox,
#fileuploadContratos .plupload_container .plupload_content.plupload_dropbox, #fileuploadAutorizacion .plupload_container .plupload_content.plupload_dropbox, #fileuploadCuentasAnualesAf .plupload_container .plupload_content.plupload_dropbox {
  overflow: initial;
}
/* line 5310, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_droptext, #fileuploadResidencia .plupload_container .plupload_droptext, #fileuploadEstatutos .plupload_container .plupload_droptext, #fileuploadDniAf .plupload_container .plupload_droptext,
#fileuploadContratoAf .plupload_container .plupload_droptext, #fileuploadLogoAf .plupload_container .plupload_droptext, #fileuploadReciboDomicilio .plupload_container .plupload_droptext, #fileuploadDniFrontal .plupload_container .plupload_droptext,
#fileuploadDniTrasera .plupload_container .plupload_droptext, #fileuploadPasaporte .plupload_container .plupload_droptext, #fileuploadCertificado .plupload_container .plupload_droptext, #fileuploadIban .plupload_container .plupload_droptext,
#fileuploadNomina .plupload_container .plupload_droptext, #fileuploadContratoInem .plupload_container .plupload_droptext, #fileuploadIRPF .plupload_container .plupload_droptext, #fileuploadCarta .plupload_container .plupload_droptext, #fileuploadDesempleo .plupload_container .plupload_droptext,
#fileuploadCarneTrasera .plupload_container .plupload_droptext, #fileuploadCarneFrontal .plupload_container .plupload_droptext, #fileuploadIdCardTrasera .plupload_container .plupload_droptext, #fileuploadIdCardFrontal .plupload_container .plupload_droptext,
#fileuploadContratos .plupload_container .plupload_droptext, #fileuploadAutorizacion .plupload_container .plupload_droptext, #fileuploadCuentasAnualesAf .plupload_container .plupload_droptext {
  display: none;
}
/* line 5313, ../sass/_main.scss */
#fileuploadEscrituras .plupload_container .plupload_filelist_footer, #fileuploadResidencia .plupload_container .plupload_filelist_footer, #fileuploadEstatutos .plupload_container .plupload_filelist_footer, #fileuploadDniAf .plupload_container .plupload_filelist_footer,
#fileuploadContratoAf .plupload_container .plupload_filelist_footer, #fileuploadLogoAf .plupload_container .plupload_filelist_footer, #fileuploadReciboDomicilio .plupload_container .plupload_filelist_footer, #fileuploadDniFrontal .plupload_container .plupload_filelist_footer,
#fileuploadDniTrasera .plupload_container .plupload_filelist_footer, #fileuploadPasaporte .plupload_container .plupload_filelist_footer, #fileuploadCertificado .plupload_container .plupload_filelist_footer, #fileuploadIban .plupload_container .plupload_filelist_footer,
#fileuploadNomina .plupload_container .plupload_filelist_footer, #fileuploadContratoInem .plupload_container .plupload_filelist_footer, #fileuploadIRPF .plupload_container .plupload_filelist_footer, #fileuploadCarta .plupload_container .plupload_filelist_footer, #fileuploadDesempleo .plupload_container .plupload_filelist_footer,
#fileuploadCarneTrasera .plupload_container .plupload_filelist_footer, #fileuploadCarneFrontal .plupload_container .plupload_filelist_footer, #fileuploadIdCardTrasera .plupload_container .plupload_filelist_footer, #fileuploadIdCardFrontal .plupload_container .plupload_filelist_footer,
#fileuploadContratos .plupload_container .plupload_filelist_footer, #fileuploadAutorizacion .plupload_container .plupload_filelist_footer, #fileuploadCuentasAnualesAf .plupload_container .plupload_filelist_footer {
  color: #000;
  background: #fff;
  border: 0;
}
/* line 5320, ../sass/_main.scss */
#fileuploadEscrituras .ui-state-default, #fileuploadEscrituras .ui-widget-content .ui-state-default, #fileuploadEscrituras .ui-widget-header .ui-state-default, #fileuploadResidencia .ui-state-default, #fileuploadResidencia .ui-widget-content .ui-state-default, #fileuploadResidencia .ui-widget-header .ui-state-default, #fileuploadEstatutos .ui-state-default, #fileuploadEstatutos .ui-widget-content .ui-state-default, #fileuploadEstatutos .ui-widget-header .ui-state-default, #fileuploadDniAf .ui-state-default, #fileuploadDniAf .ui-widget-content .ui-state-default, #fileuploadDniAf .ui-widget-header .ui-state-default,
#fileuploadContratoAf .ui-state-default,
#fileuploadContratoAf .ui-widget-content .ui-state-default,
#fileuploadContratoAf .ui-widget-header .ui-state-default, #fileuploadLogoAf .ui-state-default, #fileuploadLogoAf .ui-widget-content .ui-state-default, #fileuploadLogoAf .ui-widget-header .ui-state-default, #fileuploadReciboDomicilio .ui-state-default, #fileuploadReciboDomicilio .ui-widget-content .ui-state-default, #fileuploadReciboDomicilio .ui-widget-header .ui-state-default, #fileuploadDniFrontal .ui-state-default, #fileuploadDniFrontal .ui-widget-content .ui-state-default, #fileuploadDniFrontal .ui-widget-header .ui-state-default,
#fileuploadDniTrasera .ui-state-default,
#fileuploadDniTrasera .ui-widget-content .ui-state-default,
#fileuploadDniTrasera .ui-widget-header .ui-state-default, #fileuploadPasaporte .ui-state-default, #fileuploadPasaporte .ui-widget-content .ui-state-default, #fileuploadPasaporte .ui-widget-header .ui-state-default, #fileuploadCertificado .ui-state-default, #fileuploadCertificado .ui-widget-content .ui-state-default, #fileuploadCertificado .ui-widget-header .ui-state-default, #fileuploadIban .ui-state-default, #fileuploadIban .ui-widget-content .ui-state-default, #fileuploadIban .ui-widget-header .ui-state-default,
#fileuploadNomina .ui-state-default,
#fileuploadNomina .ui-widget-content .ui-state-default,
#fileuploadNomina .ui-widget-header .ui-state-default, #fileuploadContratoInem .ui-state-default, #fileuploadContratoInem .ui-widget-content .ui-state-default, #fileuploadContratoInem .ui-widget-header .ui-state-default, #fileuploadIRPF .ui-state-default, #fileuploadIRPF .ui-widget-content .ui-state-default, #fileuploadIRPF .ui-widget-header .ui-state-default, #fileuploadCarta .ui-state-default, #fileuploadCarta .ui-widget-content .ui-state-default, #fileuploadCarta .ui-widget-header .ui-state-default, #fileuploadDesempleo .ui-state-default, #fileuploadDesempleo .ui-widget-content .ui-state-default, #fileuploadDesempleo .ui-widget-header .ui-state-default,
#fileuploadCarneTrasera .ui-state-default,
#fileuploadCarneTrasera .ui-widget-content .ui-state-default,
#fileuploadCarneTrasera .ui-widget-header .ui-state-default, #fileuploadCarneFrontal .ui-state-default, #fileuploadCarneFrontal .ui-widget-content .ui-state-default, #fileuploadCarneFrontal .ui-widget-header .ui-state-default, #fileuploadIdCardTrasera .ui-state-default, #fileuploadIdCardTrasera .ui-widget-content .ui-state-default, #fileuploadIdCardTrasera .ui-widget-header .ui-state-default, #fileuploadIdCardFrontal .ui-state-default, #fileuploadIdCardFrontal .ui-widget-content .ui-state-default, #fileuploadIdCardFrontal .ui-widget-header .ui-state-default,
#fileuploadContratos .ui-state-default,
#fileuploadContratos .ui-widget-content .ui-state-default,
#fileuploadContratos .ui-widget-header .ui-state-default, #fileuploadAutorizacion .ui-state-default, #fileuploadAutorizacion .ui-widget-content .ui-state-default, #fileuploadAutorizacion .ui-widget-header .ui-state-default, #fileuploadCuentasAnualesAf .ui-state-default, #fileuploadCuentasAnualesAf .ui-widget-content .ui-state-default, #fileuploadCuentasAnualesAf .ui-widget-header .ui-state-default {
  background-image: none;
}
/* line 5323, ../sass/_main.scss */
#fileuploadEscrituras .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadResidencia .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadEstatutos .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadDniAf .plupload_filelist.plupload_filelist_header.ui-widget-header,
#fileuploadContratoAf .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadLogoAf .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadReciboDomicilio .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadDniFrontal .plupload_filelist.plupload_filelist_header.ui-widget-header,
#fileuploadDniTrasera .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadPasaporte .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadCertificado .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadIban .plupload_filelist.plupload_filelist_header.ui-widget-header,
#fileuploadNomina .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadContratoInem .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadIRPF .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadCarta .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadDesempleo .plupload_filelist.plupload_filelist_header.ui-widget-header,
#fileuploadCarneTrasera .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadCarneFrontal .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadIdCardTrasera .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadIdCardFrontal .plupload_filelist.plupload_filelist_header.ui-widget-header,
#fileuploadContratos .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadAutorizacion .plupload_filelist.plupload_filelist_header.ui-widget-header, #fileuploadCuentasAnualesAf .plupload_filelist.plupload_filelist_header.ui-widget-header {
  color: #000;
  background: #fff;
  border: 0;
}

@media (max-width: 991px) {
  /* line 5333, ../sass/_main.scss */
  #opciones-inversion #boton-basico, #opciones-inversion #boton-avanzado {
    display: block;
    width: 100%;
  }
  /* line 5338, ../sass/_main.scss */
  #opciones-inversion #conservador, #opciones-inversion #moderado, #opciones-inversion #agresivo {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* line 5345, ../sass/_main.scss */
.amortizarPagar {
  margin-bottom: 2.5em;
}
/* line 5347, ../sass/_main.scss */
.amortizarPagar .btn-prestatario {
  padding: 1em;
  width: 49%;
}

/* line 5353, ../sass/_main.scss */
.filterInvierte {
  display: none;
}
/* line 5356, ../sass/_main.scss */
.filterInvierte .tipoPrestamo label, .filterInvierte .categoriaPrestamo label {
  font-weight: 400;
  margin-right: 2em;
}
/* line 5362, ../sass/_main.scss */
.filterInvierte .rentabilidadPrestamo p, .filterInvierte .cantidadPrestamo p, .filterInvierte .plazoPrestamo p {
  margin: 10px 0 5px;
}

/* line 5368, ../sass/_main.scss */
.li-comentarios {
  height: 335px;
  overflow-y: scroll;
}

/* line 5372, ../sass/_main.scss */
.comentarioList {
  padding: 0;
}
/* line 5374, ../sass/_main.scss */
.comentarioList li.media {
  margin-top: -20px;
}
/* line 5376, ../sass/_main.scss */
.comentarioList li.media .preguntas {
  padding: 0;
}

/* line 5382, ../sass/_main.scss */
.listado-new {
  color: red;
  font-weight: 700;
}

/* line 5387, ../sass/_main.scss */
#creditosProblematicos_filter {
  display: block;
}

/* line 5393, ../sass/_main.scss */
#capa-tabla-amort .editable-click.editable-disabled, #capa-tabla-amort a.editable-click.editable-disabled, #capa-tabla-amort a.editable-click.editable-disabled:hover {
  color: #000;
}
/* line 5397, ../sass/_main.scss */
#capa-tabla-amort .estados-cuotas .editable-click.editable-disabled, #capa-tabla-amort .estados-cuotas a.editable-click.editable-disabled, #capa-tabla-amort .estados-cuotas a.editable-click.editable-disabled:hover {
  color: #fff;
}

/* line 5405, ../sass/_main.scss */
#ibanForm .form-group.has-feedback input#iban {
  margin-bottom: 0;
}

/* line 5414, ../sass/_main.scss */
.filtroMiCartera {
  font-size: 13px;
  color: #737373;
  cursor: pointer;
}

/* line 5421, ../sass/_main.scss */
.filterInversiones {
  display: none;
  margin: 15px 0;
}
/* line 4, ../sass/_mixins.scss */
.filterInversiones {
  *zoom: 1;
}
/* line 7, ../sass/_mixins.scss */
.filterInversiones:before, .filterInversiones:after {
  content: "";
  display: table;
}
/* line 12, ../sass/_mixins.scss */
.filterInversiones:after {
  clear: both;
}
/* line 5426, ../sass/_main.scss */
.filterInversiones .tipoPrestamo label, .filterInversiones .estadoPrestamo label, .filterInversiones .inversionPrestamo label {
  font-weight: 400;
  margin-right: 2em;
}
/* line 5429, ../sass/_main.scss */
.filterInversiones .tipoPrestamo label input[type=checkbox], .filterInversiones .estadoPrestamo label input[type=checkbox], .filterInversiones .inversionPrestamo label input[type=checkbox] {
  margin: 4px 3px 0;
}
/* line 5434, ../sass/_main.scss */
.filterInversiones .filtroTextoSlider {
  border: 0;
  width: 100%;
  text-align: center;
}
/* line 5442, ../sass/_main.scss */
.filterInversiones .estadoPrestamo label {
  margin-right: 7em;
}
/* line 5447, ../sass/_main.scss */
.filterInversiones .rentabilidadPrestamo p, .filterInversiones .cantidadPrestamo p, .filterInversiones .plazoPrestamo p, .filterInversiones .inversionPrestamo p {
  margin: 10px 0 5px;
}

/* line 5453, ../sass/_main.scss */
.filtroTextoSlider {
  border: 0;
  width: 100%;
  text-align: center;
}

/* line 5459, ../sass/_main.scss */
.filterMovimientos {
  display: none;
  margin: 15px 0;
}
/* line 4, ../sass/_mixins.scss */
.filterMovimientos {
  *zoom: 1;
}
/* line 7, ../sass/_mixins.scss */
.filterMovimientos:before, .filterMovimientos:after {
  content: "";
  display: table;
}
/* line 12, ../sass/_mixins.scss */
.filterMovimientos:after {
  clear: both;
}
/* line 5464, ../sass/_main.scss */
.filterMovimientos .tipoMovimiento label {
  font-weight: 400;
  margin-right: 8em;
}
/* line 5467, ../sass/_main.scss */
.filterMovimientos .tipoMovimiento label input[type=checkbox] {
  margin: 4px 3px 0px;
}

/* line 5475, ../sass/_main.scss */
.contenedor-tabla-panel-control .dataTables_filter {
  display: none;
}

/* line 5480, ../sass/_main.scss */
.filtroPanelControl {
  color: #000;
}

/* line 5485, ../sass/_main.scss */
.filterPanelControl label {
  width: 48%;
}

@media (min-width: 768px) {
  /* line 5491, ../sass/_main.scss */
  .modal-dialog.modalPre {
    width: 750px;
  }
}
/* line 5497, ../sass/_main.scss */
.modal-dialog.modalPre .inversor, .modal-dialog.modalPre .inversorAcreditado {
  margin-top: 40px;
}
/* line 5502, ../sass/_main.scss */
.modal-dialog.modalPre #buttonInversorAcreditado .icon {
  position: absolute;
  top: -16px;
  right: -6px;
  background: white;
  padding-left: 4px;
}
/* line 5511, ../sass/_main.scss */
.modal-dialog.modalPre #buttonInversorAcreditado.active .icon, .modal-dialog.modalPre #buttonInversorAcreditado:hover .icon {
  background: #ffffff;
  color: #009bae;
}

/* line 5526, ../sass/_main.scss */
.navbar-search ul.ss-box li.ss-result a p {
  padding: 0;
}
/* line 5528, ../sass/_main.scss */
.navbar-search ul.ss-box li.ss-result a p span.type {
  width: 12px;
  height: 40px;
  float: left;
  margin-right: 5px;
  border-radius: 5px;
}
/* line 5538, ../sass/_main.scss */
.navbar-search ul.ss-box li.ss-result a.affiliate p span.type {
  background: #002c3e;
}
/* line 5545, ../sass/_main.scss */
.navbar-search ul.ss-box li.ss-result a.borrower p span.type {
  background: #009bae;
}
/* line 5552, ../sass/_main.scss */
.navbar-search ul.ss-box li.ss-result a.investor p span.type {
  background: #009bae;
}
/* line 5559, ../sass/_main.scss */
.navbar-search ul.ss-box li.ss-result a.other p span.type {
  background: #a161ad;
}

/* line 1, ../sass/_mainConfia.scss */
#wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -200px;
  padding: 0 0 200px;
}

/* line 7, ../sass/_mainConfia.scss */
#footer {
  height: 200px;
  font-family: "Noto Sans", "sans-serif";
  font-size: 11px;
  text-shadow: 1px 1px 2px rgba(160, 160, 160, 0.4);
}

/* line 15, ../sass/_mainConfia.scss */
.navbar-footer {
  padding-bottom: 45px;
  height: 200px;
}

/* line 20, ../sass/_mainConfia.scss */
.row-logo-index {
  margin-top: 5px;
}

/* line 24, ../sass/_mainConfia.scss */
.fondoprincipal {
  margin-bottom: 0;
}

/* line 28, ../sass/_mainConfia.scss */
.fondomain {
  margin-top: 90px;
  background-size: cover;
}
@media (max-width: 1200px) {
  /* line 28, ../sass/_mainConfia.scss */
  .fondomain {
    background-size: contain;
  }
}

/* line 36, ../sass/_mainConfia.scss */
.texto-principal-index {
  margin-top: 35px;
  margin-left: -25px;
  font-family: "Noto Sans", "sans-serif";
  font-weight: 700;
  font-size: 30pt;
  line-height: 30pt;
  color: #ffffff;
  text-shadow: none;
}

/* line 47, ../sass/_mainConfia.scss */
.input-new, .select-new {
  border-bottom: 2px solid #002c3e;
  color: #002c3e;
}

/* line 52, ../sass/_mainConfia.scss */
.std-text {
  font-family: "Noto Sans", "sans-serif";
  color: #002c3e;
  font-size: 18px;
  font-weight: 300;
}

/* line 59, ../sass/_mainConfia.scss */
.std-text-confia-public {
  font-family: "Noto Sans", "sans-serif";
  color: #002c3e;
  font-size: 18pt;
  font-weight: 100;
  letter-spacing: -0.9pt;
}

/* line 67, ../sass/_mainConfia.scss */
.title-blue {
  font-family: "Noto Sans", "sans-serif";
  color: #009bae;
  font-size: 24px;
  font-weight: 700;
}

/* line 74, ../sass/_mainConfia.scss */
.title-black {
  color: #002c3e;
  font-family: "Noto Sans", "sans-serif";
  letter-spacing: -0.9pt;
}

/* line 80, ../sass/_mainConfia.scss */
.title-black-lg {
  color: #002c3e;
  font-family: "Noto Sans", "sans-serif";
  letter-spacing: -0.9pt;
}

/* line 86, ../sass/_mainConfia.scss */
.title-big {
  text-shadow: none;
  font-weight: 500;
}

/*.title-big.single-title-box {
  text-align: center;
  padding: 135px 350px;
}*/
/* line 96, ../sass/_mainConfia.scss */
.title-big.quadra-title-box {
  text-align: center;
  padding: 71px 0;
}

/* line 101, ../sass/_mainConfia.scss */
.color-inversor {
  color: #009bae;
}

/* line 105, ../sass/_mainConfia.scss */
.color-afiliado {
  color: #002c3e;
}

/* line 109, ../sass/_mainConfia.scss */
.modal-title {
  color: #002c3e;
}

/* line 113, ../sass/_mainConfia.scss */
.public-circle {
  background: #002c3e;
  border-radius: 100px;
  color: #009bae;
  height: 100px;
  font-weight: bold;
  width: 100px;
  display: inline-table;
  margin: 20px;
  text-align: center;
  vertical-align: middle;
}

/* line 125, ../sass/_mainConfia.scss */
.public-circle p {
  vertical-align: middle;
  display: table-cell;
  font-size: 25pt;
  font-weight: 300;
}

/* line 132, ../sass/_mainConfia.scss */
.btn-base {
  font-family: "Noto Sans", "sans-serif";
}

/* line 135, ../sass/_mainConfia.scss */
.btn-simulador {
  border: 0;
  border-radius: 0;
  padding: 30px 12px;
  color: #333;
}
/* line 140, ../sass/_mainConfia.scss */
.btn-simulador.afiliado {
  background: #9ec9da;
  border-left: 1px solid #0b2e3c;
  border-top: 1px solid #0b2e3c;
}
/* line 144, ../sass/_mainConfia.scss */
.btn-simulador.afiliado.off {
  background: #888;
}
/* line 147, ../sass/_mainConfia.scss */
.btn-simulador.afiliado:first-child {
  border-left: none;
}
/* line 150, ../sass/_mainConfia.scss */
.btn-simulador.afiliado.active, .btn-simulador.afiliado.focus {
  background-color: #bdd5e2;
  outline: none;
}
/* line 155, ../sass/_mainConfia.scss */
.btn-simulador.prestatario {
  background: #eff8ff;
  border-left: 1px solid #b9e1f5;
}
/* line 158, ../sass/_mainConfia.scss */
.btn-simulador.prestatario:first-child {
  border-left: none;
}
/* line 161, ../sass/_mainConfia.scss */
.btn-simulador.prestatario.active, .btn-simulador.prestatario.focus {
  background-color: #b9e1f5;
  outline: none;
}

/* line 168, ../sass/_mainConfia.scss */
.ayuda-solicitud {
  background-color: transparent;
}

/* line 173, ../sass/_mainConfia.scss */
.panel-group.faqs a {
  font-weight: 300;
}
/* line 176, ../sass/_mainConfia.scss */
.panel-group.faqs .fa.fa-caret-right, .panel-group.faqs .fa.fa-caret-down {
  color: #009bae;
  font-size: 20pt;
}

/* line 182, ../sass/_mainConfia.scss */
.submenu-li {
  border: 2px solid #002c3e;
}
/* line 184, ../sass/_mainConfia.scss */
.submenu-li.sub-active {
  border: 1px solid #002c3e;
}
/* line 187, ../sass/_mainConfia.scss */
.submenu-li a {
  font-weight: 400;
}

/* line 192, ../sass/_mainConfia.scss */
.confiaul {
  list-style: none;
  /* Remove default bullets */
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  color: #002c3e;
}

/* line 199, ../sass/_mainConfia.scss */
.confiaul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #009bae;
  /* Change the color */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
  line-height: 2em;
}

/* line 208, ../sass/_mainConfia.scss */
a {
  text-decoration: none;
}
/* line 210, ../sass/_mainConfia.scss */
a.link-gray-underline {
  color: #002c3e;
  text-decoration: underline;
}
/* line 213, ../sass/_mainConfia.scss */
a.link-gray-underline:hover {
  color: #002c3e;
  text-decoration: underline;
}

/* line 2, ../sass/_admin.scss */
.admin .affiliatePanel {
  min-height: 900px;
}
/* line 6, ../sass/_admin.scss */
.admin .investorPanel {
  min-height: 915px;
}
/* line 10, ../sass/_admin.scss */
.admin .borrowerPanel {
  min-height: 1300px;
}
/* line 15, ../sass/_admin.scss */
.admin .panel-default .panel-heading {
  background: #dddddd;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
/* line 25, ../sass/_admin.scss */
.iconWait {
  height: 50px;
}
/* line 27, ../sass/_admin.scss */
.iconWait .iconWait1, .iconWait .iconWait2, .iconWait .iconWait3 {
  width: 75%;
  height: 10px;
  display: block;
  background: #d4d4d4;
  margin-bottom: 5px;
  font: italic bold 5em/1 Bodoni, serif;
  color: #555;
  text-align: center;
  animation: fadeIn 1s infinite alternate;
}
/* line 38, ../sass/_admin.scss */
.iconWait .iconWait2 {
  width: 60%;
}
/* line 41, ../sass/_admin.scss */
.iconWait .iconWait3 {
  width: 30%;
}

/**
 * CONTENTS
 *
 * #Introduction........Naming conventions used throughout the code.
 *
 * #SETTINGS
 * Variables............Globally-available variables and config.
 *
 * #TOOLS
 * Mixins...............Useful mixins.
 *
 * #GENERIC
 * Demo styles..........Styles for demo only (consider removing these).
 *
 * #BASE
 * Raw styles...........The very basic component wrapper.
 * Modifiers............The basic styles dependant on component placement.
 * Debuggers............The basic styles dependant on component placement.
 *
 * #BUTTONS
 * Base..................Wrapping and constraining every button.
 * Modifiers.............Styles that depends on state and settings.
 * Animations............Main animations of the component.
 * Debuggers.............Styles for development.
 *
 * #LABELS
 * Base..................Wrapping and constraining every label.
 * Modifiers.............Styles that depends on state and settings.
 * Debuggers.............Styles for development.
 *
 * #DEVELOPMENT
 * In development........These styles are in development and not yet finalised
 * Debuggers.............Helper styles and flags for development.
 */
/*------------------------------------*\
    #Introduction
\*------------------------------------*/
/**
 * The code AND the comments use naming conventions to refer to each part of
 * the UI put in place by this component. If you see that somewhere they are
 * not followed please consider a Pull Request. The naming conventions are:
 *
 * "Component" : the widget itself as a whole. This is the last time it will be
 *               called anything different than "component". So, stay away from
 *               "widget", "button" or anything else when referring to the
 *               Component in general.
 *
 * "Main Button" : the button that is always in view. Hovering or clicking on it
 *                 will reveal the child buttons.
 *
 * "Child buttons" : if you've read the previous point you know what they are.
 *                   Did you read the previous point? :)
 *
 * "Label(s)" : the tooltip that fades in when hovering over a button.

/*------------------------------------*\
    #SETTINGS | Variables
\*------------------------------------*/
/**
 * These variables are the default styles that serve as fallback and can be
 * easily customised at compile time.
 * Consider overriding them in your own style sheets rather than editing them
 * here. Refer to the docs for more info.
 */
/* COLORS ----------------------------*/
/* EFFECTS ---------------------------*/
/* SPEEDS ----------------------------*/
/* SIZES -----------------------------*/
/* SPACING ---------------------------*/
/* OTHER VARIABLES -------------------*/
/*------------------------------------*\
    #BASE | Raw styles
\*------------------------------------*/
/**
 * The very core styling of the button.
 * These styles are shared by every instance of the button.
 * Styles placed here should NOT care about placement in the screen,
 * options chosen by the user or state of the button.
 */
/* line 139, ../sass/mfb.scss */
.mfb-component--tl, .mfb-component--tr, .mfb-component--bl, .mfb-component--br {
  box-sizing: border-box;
  margin: 25px;
  position: fixed;
  white-space: nowrap;
  z-index: 30;
  padding-left: 0;
  list-style: none;
}
/* line 152, ../sass/mfb.scss */
.mfb-component--tl *, .mfb-component--tr *, .mfb-component--bl *, .mfb-component--br *, .mfb-component--tl *:before, .mfb-component--tr *:before, .mfb-component--bl *:before, .mfb-component--br *:before, .mfb-component--tl *:after, .mfb-component--tr *:after, .mfb-component--bl *:after, .mfb-component--br *:after {
  box-sizing: inherit;
}
/* line 155, ../sass/mfb.scss */
.mfb-component--tl a:hover, .mfb-component--tr a:hover, .mfb-component--bl a:hover, .mfb-component--br a:hover {
  color: #ffffff;
}

/*------------------------------------*\
    #BASE | Modifiers
\*------------------------------------*/
/**
 * These styles depends on the placement of the button.
 * Styles can be:
 * 1. Top-left:  modified by the " --tl " suffix.
 * 2. Top-right: modified by the " --tr " suffix.
 * 3. Bottom-left:  modified by the " --bl " suffix.
 * 4. Bottom-right: modified by the " --br " suffix.
 */
/* line 173, ../sass/mfb.scss */
.mfb-component--tl {
  left: 0;
  top: 0;
}

/* line 177, ../sass/mfb.scss */
.mfb-component--tr {
  right: 0;
  top: 0;
}

/* line 181, ../sass/mfb.scss */
.mfb-component--bl {
  left: 0;
  bottom: 0;
}

/* line 185, ../sass/mfb.scss */
.mfb-component--br {
  right: 0;
  bottom: 0;
}

/*------------------------------------*\
    #BUTTONS | Base
\*------------------------------------*/
/* line 195, ../sass/mfb.scss */
.mfb-component__button--main, .mfb-component__button--child {
  background-color: #cca055;
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-user-drag: none;
  color: #f1f1f1;
}

/**
 * This is the unordered list for the list items that contain
 * the child buttons.
 *
 */
/* line 215, ../sass/mfb.scss */
.mfb-component__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* line 219, ../sass/mfb.scss */
.mfb-component__list > li {
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 10px 0;
  margin: -10px 0;
}

/**
 * These are the basic styles for all the icons inside the main button
 */
/* line 232, ../sass/mfb.scss */
.mfb-component__icon, .mfb-component__main-icon--active,
.mfb-component__main-icon--resting, .mfb-component__child-icon {
  position: absolute;
  font-size: 18px;
  text-align: center;
  line-height: 56px;
  width: 100%;
}

/* line 240, ../sass/mfb.scss */
.mfb-component__wrap {
  padding: 25px;
  margin: -25px;
}

/* line 251, ../sass/mfb.scss */
[data-mfb-toggle="hover"]:hover .mfb-component__icon, [data-mfb-toggle="hover"]:hover .mfb-component__main-icon--active,
[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--resting, [data-mfb-toggle="hover"]:hover .mfb-component__child-icon,
[data-mfb-state="open"] .mfb-component__icon,
[data-mfb-state="open"] .mfb-component__main-icon--active,
[data-mfb-state="open"] .mfb-component__main-icon--resting,
[data-mfb-state="open"] .mfb-component__child-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

/*------------------------------------*\
    #BUTTONS | Modifiers
\*------------------------------------*/
/* line 262, ../sass/mfb.scss */
.mfb-component__button--main {
  height: 56px;
  width: 56px;
  z-index: 20;
}

/* line 268, ../sass/mfb.scss */
.mfb-component__button--child {
  height: 56px;
  width: 56px;
}

/* line 274, ../sass/mfb.scss */
.mfb-component__main-icon--active,
.mfb-component__main-icon--resting {
  -webkit-transform: scale(1) rotate(360deg);
  transform: scale(1) rotate(360deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
}

/* line 283, ../sass/mfb.scss */
.mfb-component__child-icon,
.mfb-component__child-icon {
  line-height: 56px;
  font-size: 18px;
}

/* line 289, ../sass/mfb.scss */
.mfb-component__main-icon--active {
  opacity: 0;
}

/* line 294, ../sass/mfb.scss */
[data-mfb-toggle="hover"]:hover .mfb-component__main-icon,
[data-mfb-state="open"] .mfb-component__main-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
/* line 298, ../sass/mfb.scss */
[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--resting,
[data-mfb-state="open"] .mfb-component__main-icon--resting {
  opacity: 0;
  position: absolute !important;
}
/* line 303, ../sass/mfb.scss */
[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--active,
[data-mfb-state="open"] .mfb-component__main-icon--active {
  opacity: 1;
}

/*------------------------------------*\
    #BUTTONS | Animations
\*------------------------------------*/
/**
 * SLIDE IN + FADE
 * When hovering the main button, the child buttons slide out from beneath
 * the main button while transitioning from transparent to opaque.
 *
 */
/* line 12, ../sass/_/_slidein.scss */
.mfb-component--tl.mfb-slidein .mfb-component__list li,
.mfb-component--tr.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}
/* line 19, ../sass/_/_slidein.scss */
.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}
/* line 24, ../sass/_/_slidein.scss */
.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}
/* line 24, ../sass/_/_slidein.scss */
.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px);
  transform: translateY(140px);
}
/* line 24, ../sass/_/_slidein.scss */
.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px);
  transform: translateY(210px);
}
/* line 24, ../sass/_/_slidein.scss */
.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px);
  transform: translateY(280px);
}

/* line 34, ../sass/_/_slidein.scss */
.mfb-component--bl.mfb-slidein .mfb-component__list li,
.mfb-component--br.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}
/* line 41, ../sass/_/_slidein.scss */
.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}
/* line 46, ../sass/_/_slidein.scss */
.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px);
}
/* line 46, ../sass/_/_slidein.scss */
.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
}
/* line 46, ../sass/_/_slidein.scss */
.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px);
  transform: translateY(-210px);
}
/* line 46, ../sass/_/_slidein.scss */
.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px);
  transform: translateY(-280px);
}

/**
 * SLIDE IN SPRING
 * Same as slide-in but with a springy animation.
 *
 */
/* line 11, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
/* line 17, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
}
/* line 17, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
}
/* line 17, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
}
/* line 17, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
}
/* line 24, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}
/* line 29, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}
/* line 29, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transform: translateY(140px);
  transform: translateY(140px);
}
/* line 29, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
  -webkit-transform: translateY(210px);
  transform: translateY(210px);
}
/* line 29, ../sass/_/_slidein-spring.scss */
.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
  -webkit-transform: translateY(280px);
  transform: translateY(280px);
}

/* line 40, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
/* line 46, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
}
/* line 46, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
}
/* line 46, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
}
/* line 46, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
}
/* line 53, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}
/* line 58, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px);
}
/* line 58, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
}
/* line 58, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
  -webkit-transform: translateY(-210px);
  transform: translateY(-210px);
}
/* line 58, ../sass/_/_slidein-spring.scss */
.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
  -webkit-transform: translateY(-280px);
  transform: translateY(-280px);
}

/**
 * ZOOM-IN
 * When hovering the main button, the child buttons grow
 * from zero to normal size.
 *
 */
/* line 13, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin .mfb-component__list li,
.mfb-component--tr.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
/* line 19, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(0);
  transform: translateY(70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
/* line 19, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(0);
  transform: translateY(140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
/* line 19, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(0);
  transform: translateY(210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
/* line 19, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(0);
  transform: translateY(280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}
/* line 33, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(1);
  transform: translateY(70px) scale(1);
  transition-delay: 0.05s;
}
/* line 33, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(1);
  transform: translateY(140px) scale(1);
  transition-delay: 0.1s;
}
/* line 33, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(1);
  transform: translateY(210px) scale(1);
  transition-delay: 0.15s;
}
/* line 33, ../sass/_/_zoomin.scss */
.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(1);
  transform: translateY(280px) scale(1);
  transition-delay: 0.2s;
}

/* line 47, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin .mfb-component__list li,
.mfb-component--br.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
/* line 53, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(0);
  transform: translateY(-70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
/* line 53, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(0);
  transform: translateY(-140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
/* line 53, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(0);
  transform: translateY(-210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
/* line 53, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(0);
  transform: translateY(-280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}
/* line 67, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(1);
  transform: translateY(-70px) scale(1);
  transition-delay: 0.05s;
}
/* line 67, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(1);
  transform: translateY(-140px) scale(1);
  transition-delay: 0.1s;
}
/* line 67, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(1);
  transform: translateY(-210px) scale(1);
  transition-delay: 0.15s;
}
/* line 67, ../sass/_/_zoomin.scss */
.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(1);
  transform: translateY(-280px) scale(1);
  transition-delay: 0.2s;
}

/**
 * FOUNTAIN
 * When hovering the main button the child buttons
 * jump into view from outside the viewport
 */
/* line 12, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain .mfb-component__list li,
.mfb-component--tr.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
/* line 18, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(0);
  transform: translateY(-70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
/* line 18, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(0);
  transform: translateY(-140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
/* line 18, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(0);
  transform: translateY(-210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
/* line 18, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(0);
  transform: translateY(-280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}
/* line 32, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(1);
  transform: translateY(70px) scale(1);
  transition-delay: 0.05s;
}
/* line 32, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(1);
  transform: translateY(140px) scale(1);
  transition-delay: 0.1s;
}
/* line 32, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(1);
  transform: translateY(210px) scale(1);
  transition-delay: 0.15s;
}
/* line 32, ../sass/_/_fountain.scss */
.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(1);
  transform: translateY(280px) scale(1);
  transition-delay: 0.2s;
}

/* line 46, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain .mfb-component__list li,
.mfb-component--br.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
/* line 52, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(0);
  transform: translateY(70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
/* line 52, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(0);
  transform: translateY(140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
/* line 52, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(0);
  transform: translateY(210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
/* line 52, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(0);
  transform: translateY(280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}
/* line 66, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(1);
  transform: translateY(-70px) scale(1);
  transition-delay: 0.05s;
}
/* line 66, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(1);
  transform: translateY(-140px) scale(1);
  transition-delay: 0.1s;
}
/* line 66, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(1);
  transform: translateY(-210px) scale(1);
  transition-delay: 0.15s;
}
/* line 66, ../sass/_/_fountain.scss */
.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(1);
  transform: translateY(-280px) scale(1);
  transition-delay: 0.2s;
}

/*------------------------------------*\
    #LABELS | base
\*------------------------------------*/
/**
 * These are the labels associated to each button,
 * exposed only when hovering the related button.
 * They are called labels but are in fact data-attributes of
 * each button (an anchor tag).
 */
/* line 342, ../sass/mfb.scss */
[data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 0;
  transition: all 0.5s;
  background: rgba(0, 0, 0, 0.4);
  padding: 4px 10px;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: normal;
  pointer-events: none;
  line-height: normal;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  transition: all 0.5s;
}

/* line 359, ../sass/mfb.scss */
[data-mfb-toggle="hover"] [data-mfb-label]:hover:after,
[data-mfb-state="open"] [data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 1;
  transition: all 0.3s;
}

/*------------------------------------*\
    #LABELS | Modifiers
\*------------------------------------*/
/* line 369, ../sass/mfb.scss */
.mfb-component--br [data-mfb-label]:after, .mfb-component--tr [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 70px;
}

/* line 375, ../sass/mfb.scss */
.mfb-component--br .mfb-component__list [data-mfb-label]:after, .mfb-component--tr .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 70px;
}

/* line 381, ../sass/mfb.scss */
.mfb-component--tl [data-mfb-label]:after, .mfb-component--bl [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 70px;
}

/* line 387, ../sass/mfb.scss */
.mfb-component--tl .mfb-component__list [data-mfb-label]:after, .mfb-component--bl .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 70px;
}

/*------------------------------------*\
    #DEVELOPMENT | In development
\*------------------------------------*/
/**
 * This part is where unfinished code should stay.
 * When a feature is ready(sh) move these styles to their proper place.
 */
/*------------------------------------*\
    #DEVELOPMENT | Debuggers
\*------------------------------------*/
/**
 * These are mainly helpers for development. They do not have to end up
 * in production but it's handy to keep them when developing.
 */
/**
 * Apply this class to the html tag when developing the slide-in button
 */
/**
 * @file
 * Print styling
 */
@media print {
  /* Underline all links. */
  /* line 9, ../sass/_print.scss */
  a:link,
  a:visited {
    text-decoration: underline !important;
    /* Don't underline header. */
  }
  /* line 14, ../sass/_print.scss */
  a:link.header__site-link,
  a:visited.header__site-link {
    text-decoration: none !important;
  }

  /* line 19, ../sass/_print.scss */
  #content {
    /* Add visible URL after links. */
    /* Only display useful links. */
    /* Add visible title after abbreviations. */
  }
  /* line 21, ../sass/_print.scss */
  #content a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 14px;
  }
  /* line 28, ../sass/_print.scss */
  #content a[href^="javascript:"]:after,
  #content a[href^="#"]:after {
    content: "";
  }
  /* line 34, ../sass/_print.scss */
  #content abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /* Un-float the content. */
  /* line 40, ../sass/_print.scss */
  #content {
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Turn off any background colors or images. */
  /* line 48, ../sass/_print.scss */
  body,
  #page,
  #main,
  #content {
    color: #000;
    background-color: transparent !important;
    background-image: none !important;
  }

  /* Hide sidebars and nav elements. */
  /* line 58, ../sass/_print.scss */
  #skip-link,
  #toolbar,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .breadcrumb,
  .tabs,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .pager,
  .feed-icons {
    visibility: hidden;
    display: none;
  }
}
